import countryToCurrency from 'country-to-currency';
import React, { ReactNode, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Info } from 'lucide-react';
import { getCurrencyConversion } from '../utils/functions';
import supabase from '../utils/supabase';

export function CurrencyConversion({origin,destination}:{origin:string,destination:string}) {
    const [conversion, setConversion] = useState(0);
    const originCurrency = countryToCurrency[origin as keyof typeof countryToCurrency]
    const destinationCurrency = countryToCurrency[destination as keyof typeof countryToCurrency]

    useEffect(()=>{
        const getData = async () => {
            const conversionTmp = await getCurrencyConversion(originCurrency,destinationCurrency)
            if (conversionTmp) { setConversion(conversionTmp) }
            else {

            }
        }
        if (originCurrency&&destinationCurrency) {
            getData();
        }
    },[originCurrency,destinationCurrency])
    return conversion ? (
            <div className='flex items-center gap-2 text-sm'>1 {originCurrency} = {conversion} {destinationCurrency}<Link to="https://www.revolut.com/referral/?referral-code=jonasmpbf" target='_blank'><Info size={18} /></Link></div>
    ) : (
        <></>
    )
}
// https://www.revolut.com/referral/?referral-code=jonasmpbf

export function Layout({ children }:{children:ReactNode}) {
    return (
        <div className="flex flex-col items-center">
            <main className="flex-1 p-1 lg:p-8 pt-5 w-full">
                {children}
            </main>
        </div>
    );
}

export function Jonas() {
    useEffect(()=>{
        const getUsers = async () => {
            const { count } = await supabase.from('users').select('id', { count: 'exact',head: true  });
            if (count) {
                console.log(count);
            }
        }
        getUsers()
    },[])
    return (
        <div className='w-full flex flex-col lg:flex-row p-s3 gap-3 bg-red-200'>
            <div>

            Users:
            </div>
            <div>

            Vacations:
            </div>
            {/* <h1 className='text-lg font-black mb-3'>Jonas Section</h1>
            <h1 className='font-black text-gray-500 text-sm'>Version: {V}</h1>
            <h1 className='text-lg font-black mt-3'>Internal</h1>
            <div className='flex gap-2'>
                <Link className='btn btn-primary' to="/home/examplevacation">Test Vacation</Link>
                <Link className='btn btn-primary' to="/pricing">Payment Page</Link>
            </div>

            <h1 className='text-lg font-black mt-3'>External</h1>
            <div className='flex gap-2'>
                <Link className='btn btn-primary' target="_blank" to="https://supabase.com/dashboard/project/ojyikcluxfqbxcpacdvy/">Database</Link>
                <Link className='btn btn-primary' target="_blank" to="https://dashboard.stripe.com/dashboard">Stripe</Link>
                <Link className='btn btn-primary' target="_blank" to="https://platform.openai.com/">OpenAI</Link>
            </div> */}
        </div>
    )
}

export function Loading() {
    return (
        <div className='flex justify-center w-full'>
            <span className="text-primary loading loading-spinner loading-lg"></span>
        </div>
    )
}