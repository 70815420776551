const config = {
    // TODO:
    appName: "Vacation Vision",
    // TODO:: a short description of your app for SEO tags (can be overwritten)
    appDescription:"description",
    // TODO: 
    domainName: "https://jonaskaatz.com",
    backendDomain: "http://localhost:4000",
    stripe: {
        // Create multiple plans in your Stripe dashboard, then add them here. You can add as many plans as you want, just make sure to add the priceId
        plans: [
            {
                // TODO: — we use this to find the plan in the webhook (for instance if you want to update the user's credits based on the plan)
                priceId:
                process.env.NODE_ENV === "development"
                    ? "price_1PVcb1KEBgBAdcdp8PHdoPeZ"
                    : "need_to_be_configured",
                // TODO: - Name of the plan, displayed on the pricing page
                name: "Bronze Plan",
                // A friendly description of the plan, displayed on the pricing page. Tip: explain why this plan and not others
                description: "Good enough",
                // The price you want to display, the one user will be charged on Stripe.
                price: 29.9,
                // If you have an anchor price (i.e. $29) that you want to display crossed out, put it here. Otherwise, leave it empty
                // priceAnchor: 29,
                features: [
                    { name: "Feature 1" },
                    { name: "Feature 2" },
                ],
            },
            {
                priceId:
                process.env.NODE_ENV === "development"
                    ? "price_1PVcb1KEBgBAdcdp8PHdoPeZ"
                    : "need_to_be_configured_in_prod",
                // This plan will look different on the pricing page, it will be highlighted. You can only have one plan with isFeatured: true
                isFeatured: true,
                // TODO: - Name of the plan, displayed on the pricing page
                name: "Silver Plan",
                // A friendly description of the plan, displayed on the pricing page. Tip: explain why this plan and not others
                description: "OK plan",
                price: 50,
                // If you have an anchor price (i.e. $29) that you want to display crossed out, put it here. Otherwise, leave it empty
                priceAnchor: 149,
                features: [
                    { name: "Feature 1" },
                    { name: "Feature 2" },
                    { name: "Feature 3" },
                    { name: "Feature 4" },
                ],
            },
            {
                priceId:
                process.env.NODE_ENV === "development"
                    ? "price_1PVcb1KEBgBAdcdp8PHdoPeZ"
                    : "need_to_be_configured",
                name: "Gold Plan",
                description: "Best plan",
                price: 59.9,
                features: [
                    { name: "Feature 1" },
                    { name: "Feature 2" },
                    { name: "Feature 3" },
                    { name: "Feature 4" },
                    { name: "Feature 5" },
                    { name: "Feature 6" },
                ],
            },
        ],
    },
    colors: {
        // TODO: — The DaisyUI theme to use (added to the main layout.js). Leave blank for default (light & dark mode). If you any other theme than light/dark, you need to add it in config.tailwind.js in daisyui.themes.
        theme: "light",
    },
    auth: {
        // TODO: — the path to log in users. It's use to protect private routes (like /dashboard). It's used in apiClient (/libs/api.js) upon 401 errors from our API
        loginUrl: "/sign-in",
        // TODO: — the path you want to redirect users after successfull login (i.e. /dashboard, /private). This is normally a private page for users to manage their accounts. It's used in apiClient (/libs/api.js) upon 401 errors from our API & in ButtonSignIn.js
        callbackUrl: "/home",
    },
};

export default config;
