import React, { useContext, useEffect, useState } from 'react'
import Modal from './Modal';
import { useTranslation } from 'react-i18next';
import { LogIn, LogOut, TriangleAlert } from 'lucide-react';
import supabase from '../utils/supabase';
import { UserContext } from '../App';
import { useNavigate } from 'react-router-dom';

export default function Login({bigBtn,hideLogout}:{bigBtn?:boolean,hideLogout?:boolean}) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [login,setLogin] = useContext(UserContext);
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [register, setRegister] = useState(false);
    const [password2, setPassword2] = useState('');
    const [message, setMessage] = useState('');
    const [loginLoad, setLoginLoad] = useState(false);

    useEffect(()=>{;
        supabase.auth.onAuthStateChange((event, session) => {            
            if (session) {
                setLogin(session.user.id);
            }
            else setLogin('');
        })
    },[setLogin])
    


    const handleEmail = (event:any) => {
        setEmail(event.target.value);
    }
    const handlePassword = (event:any) => {
        setPassword(event.target.value);
    }
    const handlePassword2 = (event:any) => {
        setPassword2(event.target.value);
    }
    const sendForm = async (event:any) => {
        event.preventDefault()
        setLoginLoad(true)
        setMessage('')

        if (register&&password!==password2) {
            setMessage('Passwords don\'t match')
        }
        else {
            const { error } = register?await formRegister():await formLogin();
            if (error) {
                setMessage(error.message)
            }
            else {
                setOpen(false);
                navigate('/home');
                setEmail('');
                setPassword('');
                setPassword2('');
            }
        }
        setLoginLoad(false)
    }
    const formLogin = async () => {
        const { data, error } = await supabase.auth.signInWithPassword({
            email: email,
            password: password
        })
        console.log(data,error);
        
        return {data,error}
    }
    const formRegister = async () => {

        const { data, error } = await supabase.auth.signUp({
            email: email,
            password: password
        })
        console.log(data,error);
        return {data,error}
    }
    const logoutUser = async () => {
        let { error } = await supabase.auth.signOut()   
        console.log(error);
    }
    return (
        <div>
            {(login)?
            !hideLogout&&<button className={"btn btn-primary sml-5 "+(bigBtn?'btn-wide':'')} onClick={logoutUser}><LogOut/> Logout</button>
            :
            <button className={"btn btn-primary sml-5 "+(bigBtn?'btn-wide':'')} onClick={()=>setOpen(true)}><LogIn /> Login</button>
            }
            <Modal isModalOpen={open} setIsModalOpen={()=>setOpen(false)} title='Login'>
                <div className='flex flex-col items-center text-center w-full'>
                    {message && <p className='flex gap-2'><TriangleAlert />{message}<TriangleAlert /></p> }
                    <form onSubmit={sendForm} className='flex flex-col gap-3 w-96'>
                        <div className="form-control">
                            <label className="label cursor-pointer">
                                <span className="label-text">Register</span>
                                <input type="checkbox" className="toggle toggle-success" checked={register}  onChange={()=>setRegister(!register)}/>
                            </label>
                        </div>
                        <input type="email" name="email" className='input input-bordered w-full' placeholder={t("email")} value={email} onChange={handleEmail}/>
                        <input type="password" name="password" className='input input-bordered w-full' placeholder={t("password")} value={password} onChange={handlePassword}/>
                        {register && <input type="password" name="password2" className='input input-bordered w-full' placeholder={t("password2")} value={password2} onChange={handlePassword2}/>}
                        <button className='btn btn-primary'>{loginLoad?'loading..':(register?'Register':'Login')}</button>
                    </form>
                </div>
            </Modal>
        </div>
    )
}