import React, { useContext, useState } from 'react'
import Modal from './Modal'
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { sendError } from '../utils/functions';
import { Plus, Save, Trash } from 'lucide-react';
import supabase from '../utils/supabase';
import { CurrencyConversion } from './GeneralComponents';
import { UserContext } from '../App';


export default function Budget({vacation}:{vacation:VacationUser}) {
    const { t } = useTranslation();
    const [,,userData] = useContext(UserContext);
    const [open, setOpen] = useState(false)
    const [listItems, setListItems] = useState<BudgetItem[]>((vacation.budget_spendings)?vacation.budget_spendings:[]);
    const [budget, setBudget] = useState<number>((vacation.budget)?vacation.budget:0.00);
    const [editingItemId, setEditingItemId] = useState<string>('');
    const [subEditingItemId, setSubEditingItemId] = useState<BudgetItem|undefined>(undefined);

    const getLeftovers = (items:any) => {
        const key = 'amount';
        var total = 0;
        if (items) {
            for (var i = 0; i < items.length; i++) {
                let subTotal = 0;
                if (typeof items[i].amount != 'string') {
                    for (var j = 0; j < items[i].amount.length; j++) {
                        subTotal += parseFloat(items[i][key][j].amount as string);
                    }
                }
                
                
                if (items[i].hasOwnProperty(key)) {
                    if (typeof items[i][key] === 'string') {
                        total += parseFloat(items[i][key] as string);
                    }
                    else {
                        total += subTotal;
                    }
                }
            }
            const leftover = budget - total;
            return leftover.toFixed(2)
        }
        else return vacation.budget
    }
    const [leftovers, setLeftovers] = useState<number>(getLeftovers(listItems));
    const handleNew = () => {
        const newId = v4()
        setListItems((prevItems:BudgetItem[]) => [...prevItems, { id: newId, description: '', amount: '0' }]);
        // handleEdit(newId);
        handleSubEdit({ id: newId, description: '', amount: '0' });
    };
    const handleNewSub = () => {
        const newId = v4()
        if (subEditingItemId!==undefined) {
            const updatedBudgetItem = { ...subEditingItemId };
            // If it's an array, push the value
            if (Array.isArray(updatedBudgetItem.amount)) { updatedBudgetItem.amount.push({ id: newId, description: '', amount: '0' }); }
            // If it's a string, convert it to an array and push the value
            else { updatedBudgetItem.amount = [{ id: newId, amount: '0', description: '' }]; }
            setSubEditingItemId(updatedBudgetItem);
            setEditingItemId(newId)
        }
    };
    const handleBudget = (newText: string) => {
        setBudget(parseFloat(newText))
    };
    const handleItemChange = (id: string, newText: string) => {
        setSubEditingItemId((prevState: BudgetItem|undefined) => ({
            ...prevState!,
            description: newText
        }));
    };
    const handleSubItemChange = (id: string, newText: string) => {
        if (typeof subEditingItemId?.amount !== 'string') {
            const subEditingItemIdTmp = subEditingItemId?.amount.map(input =>
                input.id === id ? { ...input, description: newText } : input
            )
            setSubEditingItemId((prevState: any) => ({
                ...prevState,
                amount: subEditingItemIdTmp
            }));
        }
    };
    const handleAmountChange = (id: string, newAmountString: string) => {
        setSubEditingItemId((prevState: BudgetItem|undefined) => ({
            ...prevState!,
            amount: newAmountString
        }));
    };
    const handleSubAmountChange = (id: string, newAmountString: string) => {
        if (typeof subEditingItemId?.amount !== 'string') {
            const subEditingItemIdTmp = subEditingItemId?.amount.map(input =>
                input.id === id ? { ...input, amount: newAmountString } : input
            )
            setSubEditingItemId((prevState: any) => ({
                ...prevState,
                amount: subEditingItemIdTmp
            }));
        }
    };
    const handleEdit = (itemId:string) => {
        setEditingItemId(itemId);
        // setOpen(true);
    };
    const handleSubEdit = (item:BudgetItem) => {
        setSubEditingItemId(item);
        setOpen(true);
    };
    const handleSubSave = () => {
        setEditingItemId('');
        setLeftovers(getLeftovers(listItems));
    }
    const handleSave = () => {
        // SECTION: string
        setEditingItemId('');
        const listItemsTmp = listItems.map(input => input.id === subEditingItemId?.id ? subEditingItemId : input )
        setListItems(listItemsTmp)
        setLeftovers(getLeftovers(listItemsTmp));
        setOpen(false);
        saveInDB(listItemsTmp)
    };
    const handleDelete = (id: string) => {
        setOpen(false);
        setListItems((prevItems:BudgetItem[]) => (prevItems.filter(spending => spending.id !== id)));
        const updatedList = listItems.filter(spending => spending.id !== id);
        // saveInDB(updatedList)
        setLeftovers(getLeftovers(updatedList))
    };
    const handleSubDelete = (id: string) => {
        if (typeof subEditingItemId?.amount != 'string') {
            const updatedList = subEditingItemId?.amount.filter(spending => spending.id !== id);
            setSubEditingItemId((prevState: any) => ({
                ...prevState,
                amount: updatedList
            }));
        }
    };
    const handleSaveBudget = () => {
        setEditingItemId('');
        setLeftovers(getLeftovers(listItems));
        saveBudgetInDB(budget)
    };
    const saveInDB = async (newList:BudgetItem[]) => {
        if (vacation.id!=='examplevacation') {
            
            const {data, error} = await supabase.from('vacations').update({ 
                budget_spendings:newList,
            })
            .eq('id', vacation.id)
            .select()
            if (!data) {sendError(error)}
        }
    }
    const saveBudgetInDB = async (newBudget:number) => {
        if (Number.isNaN(newBudget)) {setBudget(0)}
        if (vacation.id!=='examplevacation') {
            const {data, error} = await supabase.from('vacations').update({ 
                budget:(Number.isNaN(newBudget))?0:newBudget,
            })
            .eq('id', vacation.id)
            .select()
            // console.log({data,error});
            if (!data) {sendError(error)}
            
        }
    }
    const calcAllSubs = (items:BudgetSubItem) => {
        let totalAmount=0;
        if (typeof items !=='string') {
            items.forEach(element => {
                totalAmount=totalAmount+Number(element.amount)
            })
        }
        return Number(totalAmount)
    }

    const daysBetween = Math.round((new Date(vacation.date_end).getTime() - new Date(vacation.date_start).getTime()) / (1000 * 3600 * 24) + 1);
    const daysUntilEnd = Math.round((new Date(vacation.date_end).getTime() - new Date().getTime()) / (1000 * 3600 * 24) + 1);

    return (
        <div className=''>
            <div className='flex flex-col justify-end'>
                <div className='flex justify-between'>
                    <div className='flex justify-end md:justify-start w-full'>
                        <CurrencyConversion origin={userData?.currency?userData.currency:'US'} destination={vacation.country} />
                    </div>

                    {editingItemId === 'budget' ? (
                        <div className='flex justify-end items-end smt-2 gap-2'>
                            <label className="form-control w-3/6 sw-full">
                                <div className="labels pl-1"><span className="label-text-alt">{t('budget')}</span></div>
                                <input onFocus={e => e.currentTarget.select()} className="input input-bordered input-sm" type="number" value={budget} onChange={(e) => handleBudget(e.target.value)} />
                            </label>
                            <button onClick={handleSaveBudget} className='btn btn-success btn-sm'><Save /></button>
                        </div>
                    ):(
                        <div className='flex justify-end'>
                            <button onClick={()=>handleEdit('budget')} className='underline text-right m-1 text-sm'>{budget.toFixed(2)}</button>
                        </div>
                    )}
                </div>

                {listItems?.map((spending:BudgetItem) => (
                    <div key={spending.id} className='flex my-1 w-full rounded-lg'>
                        <div onClick={()=>handleSubEdit(spending)} className='flex justify-between w-full rounded-lg hover:cursor-pointer border border-transparent hover:border-primary'>
                            <div className='p-2 lg:text-sm'>{spending.description}</div>
                            {typeof spending.amount == 'string'?
                            <div className={'p-2 lg:text-sm '+((Number(spending.amount)>0)?'text-red-500':'text-green-500')}>{(Number(spending.amount)*-1).toFixed(2)}</div>
                            :
                            <div className={'p-2 lg:text-sm '+((calcAllSubs(spending.amount)>0)?'text-red-500':'text-green-500')}>{(calcAllSubs(spending.amount)*-1).toFixed(2)}</div>
                            }
                        </div>
                    </div>
                ))}
                <div className='m-1 flex justify-between items-center'>
                    <button className='btn btn-sm btn-circle btn-primary' onClick={handleNew}><Plus size="22" /></button>
                    <p className='text-right text-sm'>{t("left")}: {leftovers+' ('+(leftovers / ((new Date(vacation.date_start).getTime() > new Date().getTime())?daysBetween:daysUntilEnd) ).toFixed(2) + ' ' + t("perDay")+')'}</p>
                </div>
            </div>

            <Modal isModalOpen={open} setIsModalOpen={()=>setOpen(false)} title='Budget'>
                <div className='text-center w-64s'>
                    <div className='mx-auto my-4 w-64s'>
                        {subEditingItemId && 
                        <div className='flex justify-center'>
                            <label className="form-control  w-8/12 sw-full">
                                <div className="labels pl-1"><span className="label-text-alt">{t('description')}</span></div>
                                <input onFocus={e => e.currentTarget.select()} className="input input-bordered input-sm" value={subEditingItemId?.description} onChange={(e) => handleItemChange(subEditingItemId.id,e.target.value)} />
                            </label>
                        </div>
                        }
                    </div>
                    <div className='overflow-auto max-h-[calc(100vh-200px)] w-full'>
                        {typeof subEditingItemId?.amount != 'string' ?
                        // SECTION: Object
                            <div>
                                {subEditingItemId?.amount.map((spending:BudgetItem) => (
                                    <div key={spending.id} className='flex my-1 w-full rounded-lg'>
                                    {editingItemId === spending.id ? (
                                        <div className='flex justify-between w-full gap-1 items-center p-1'>
                                            <label className="form-control w-7/12 sw-full">
                                                <div className="labels pl-1"><span className="label-text-alt">{t('description')}</span></div>
                                                <input autoFocus onFocus={e => e.currentTarget.select()} className="input input-bordered input-sm" value={spending.description} onChange={(e) => handleSubItemChange(spending.id,e.target.value)} />
                                            </label>
                                            <label className="form-control w-4/12 sw-full">
                                                <div className="labels pl-1"><span className="label-text-alt">{t('amount')}</span></div>
                                                <input onFocus={e => e.currentTarget.select()} className="input input-bordered input-sm" type="number" value={Number(spending.amount)} onChange={(e) => handleSubAmountChange(spending.id,e.target.value)} />
                                            </label>
                                            <div className='flex flex-col w-1/12 smr-2 justify-around items-center gap-1'>
                                                <button onClick={handleSubSave} className='btn btn-sm btn-circle btn-primary'><Save size={18} /></button>
                                                <button onClick={()=>handleSubDelete(spending.id)} className='btn btn-sm btn-circle btn-error'><Trash size={18} /></button>
                                            </div>
                                        </div>
                                        ):(
                                        <div onClick={()=>handleEdit(spending.id)} className='flex justify-between w-full rounded-lg hover:cursor-pointer border border-transparent hover:border-primary'>
                                            <div className='p-2 lg:text-sm'>{spending.description}</div>
                                            <div className={'p-2 lg:text-sm '+((Number(spending.amount)>0)?'text-red-500':'text-green-500')}>{(Number(spending.amount)*-1).toFixed(2)}</div>
                                        </div>
                                        )}
                                    </div>
                                ))}
                                <div className='flex justify-between items-center mt-8'>
                                    <button className='btn btn-circle btn-primary' onClick={handleNewSub}><Plus size="22" /></button>
                                    {subEditingItemId &&  <button onClick={()=>handleDelete(subEditingItemId.id)} className='btn btn-circle btn-error'><Trash /></button>}
                                </div>
                            </div>
                        :
                        // SECTION: sting
                            <div className='mt-2'>
                                <div className='flex justify-center'>
                                    <label className="form-control  w-8/12 sw-full">
                                        <div className="labels pl-1"><span className="label-text-alt">{t('amount')}</span></div>
                                        <input onFocus={e => e.currentTarget.select()} className="input input-bordered input-sm" type="number" value={subEditingItemId.amount} onChange={(e) => handleAmountChange(subEditingItemId.id,e.target.value)} />
                                    </label>
                                </div>
                                <div className='flex justify-between items-center mt-2'>
                                    <button className='btn btn-circle btn-primary' onClick={handleNewSub}><Plus size="22" /></button>
                                    <button onClick={()=>handleDelete(subEditingItemId.id)} className='btn btn-circle btn-error'><Trash /></button>
                                </div>
                            </div>
                        }
                    </div>
                    <div className='flex justify-around mt-4'>
                        <button className='btn btn-primary w-1/3' onClick={handleSave}>{t("save")}</button>
                        <button className='btn w-1/3' onClick={()=>setOpen(false)}>{t("close")}</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
