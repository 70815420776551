import { useContext, useEffect, useState } from 'react'
import { TodoCard } from './TodoCard'
import { sendError } from '../utils/functions';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { vacationContext } from '../Pages/(privat)/(destination)/DestinationsDetails';
import supabase from '../utils/supabase';
import { Circle, CircleCheck, Eye, EyeOff, Pencil, Plus, Save, Trash } from 'lucide-react';

export const TodoContainer = ({todos, vacationId}:{todos:Array<TodoItem>,vacationId:string}) => {
    const [dataVacation, setDataVacation] = useContext(vacationContext);
    const { t } = useTranslation();
    const [editingItemId, setEditingItemId] = useState<string>('');
    const [todosList, setTodosList] = useState<TodoItem[]>(todos ?? []);
    const [allDone, setAllDone] = useState(false);
    const [showOldTodo, setShowOldTodo] = useState(false);

    useEffect(()=>{
        if (todosList.length) {
            const unpacked = todosList.find(item=>item.checked===false);
            if (!unpacked) setAllDone(true)
            else setAllDone(false)
            
        }
    },[todosList])

    const handleAddNewInput = (save:boolean) => {
        if (save) { 
            if (todosList[todosList.length - 1].text==='') {
                setEditingItemId('');
                setTodosList(todosList.filter(input => input.text !== ''))
                return false
            }
            else {
                saveInDB(todosList);
            }
        }
        const newId = v4()
        setTodosList((prevTodos:TodoItem[]) => [...prevTodos, { id: newId, text: '', checked: false ,index:todosList.length+1}]);
        handleEdit(newId)
    };
    const handleEdit = (itemId:string) => {
        setEditingItemId(itemId);
    };
    const handleItemChange = (id: string, newText: string) => {
        setTodosList((prevItems:TodoItem[]) =>
            prevItems.map(input =>
                input.id === id ? { ...input, text: newText } : input
            )
        );
    };
    const handleCheckboxChange = (id: string) => {
        setTodosList((prevTodos:TodoItem[]) =>
            prevTodos.map(input =>
                input.id === id ? { ...input, checked: !input.checked } : input
            )
        );
        const updatedList = todosList.map(todo =>
            todo.id === id ? { ...todo, checked: !todo.checked } : todo
        );
        saveInDB(updatedList)
    };
    const handleSave = () => {
        setEditingItemId('');
        setTodosList(todosList.filter(input => input.text !== ''))
        saveInDB(todosList)
    };
    const handleDelete = (id: string) => {
        setTodosList((prevItems:TodoItem[]) => prevItems.filter(todo => todo.id !== id));
        const updatedList = todosList.filter(todo => todo.id !== id);
        saveInDB(updatedList)
    };
    const saveInDB = async (newList:TodoItem[]) => {
        if (vacationId!=='examplevacation') {
            const {data, error} = await supabase.from('vacations').update({ 
                todos:newList,
            })
            .eq('id', vacationId)
            .select()
            .single()
            if (dataVacation) {}
            if (data) { setDataVacation(data); }
            else { sendError(error); }
        }
    }
    const moveItem = (dragIndex: number, hoverIndex: number) => {
        const draggedItem = todosList[dragIndex];
        const updatedItems = [...todosList];
        updatedItems.splice(dragIndex, 1);
        updatedItems.splice(hoverIndex, 0, draggedItem);
        setTodosList(updatedItems);
    };
    const saveSortOrder = () => {
        saveInDB(todosList)
    };
    const toggleShowTodo = () => {
        setShowOldTodo(!showOldTodo)
    }

    return (
        <div className="flex flex-col items-start my-2s w-full">
            <div className='w-full flex justify-end mb-2'>
                {showOldTodo ? <button className='flex items-center gap-1' onClick={toggleShowTodo}>{t("hide")}<EyeOff size="22" /></button> : <button className='flex items-center gap-1' onClick={toggleShowTodo}>{t("show")}<Eye size="22" /></button> }
            </div>
            <div className='flex flex-col justify-center items-center flex-wrap w-full'>
            {todosList?.map((todo, i) => 
                (!todo.checked||showOldTodo) &&
                <TodoCard key={todo.id} index={i} id={todo.id} moveCard={moveItem} saveSortOrder={saveSortOrder} >
                    <div className={"relative flex justify-between items-center my-3 lg:my-2 p-2 lg:p-0 w-full max-w-2xl border border-transparent self-center bg-base-300 text-lg rounded-lg"} key={todo.id}>
                        <input className="w-1/12" hidden type="checkbox"  name="check" id={todo.id} checked={todo.checked} onChange={() => handleCheckboxChange(todo.id)}/>
                        {editingItemId === todo.id ? (
                            <div className='flex items-center gap-2 w-full 11/12'>
                                <button className='p-2 text-red-500 hover:text-red-600 transition' onClick={() => handleDelete(todo.id)}><Trash size="20" /></button>
                                <input className="input input-bordered input-sm w-11/12" autoFocus value={todo.text} onChange={(e) => handleItemChange(todo.id,e.target.value)} onKeyDown={(event) => { if (event.key === 'Enter') handleAddNewInput(true)}} />
                                <button className='ml-3 p-2' onClick={handleSave}><Save size="20" /></button>
                            </div>
                        ):(
                            <div className={'flex items-center w-full 11/12 transition-all '+((todo.checked)?' text-slate-400 line-through':'')}>
                                {todo.checked && <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 w-11/12 h-px bg-slate-400 "></div>}
                                <label htmlFor={todo.id} className='flex items-center p-2 hover:cursor-pointer'>{todo.checked?<CircleCheck className='' size={20}/>:<Circle size={20}/>}</label>
                                <p className='w-full lg:text-sm'>{todo.text}</p>
                                <button className='ml-3 p-2' onClick={() => handleEdit(todo.id)}><Pencil size="20" /></button>
                            </div>
                        )}
                    </div>
                </TodoCard>
            )}
            {(allDone&&!showOldTodo) && <div className='my-5 text-lg'>{t('allDone')}</div> }
            </div>
            <div className="w-full flex">
                <button className="btn btn-sm btn-circle btn-primary" type="button" onClick={()=>handleAddNewInput(false)}><Plus /></button>
            </div>
        </div>
    )
}