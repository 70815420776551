import React from 'react'
import { Link } from 'react-router-dom'
import { formatBlogTitle } from '../../../utils/functions';
import ImageWithFallback from '../../../components/ImageWithFallback';
export default function BlogCard({content}:{content:any}) {

    return (
        <Link to={'/blog/'+content.slug} className={"relative flex-1 min-w-80 md:min-w-160 max-w-full h-80 lg:h-96 m-2 rounded-xl hover:scale-[1.02] duration-200 item"}>
            <div className="absolute top-0 left-0 min-w-full min-h-full w-full h-full object-cover brightness-50">
                <ImageWithFallback src={content.slug}/>
            </div>
            <div className="absolute w-full h-full flex flex-col justify-center items-center py-2 px-3">
                <h1 className="font-semibold text-2xl text-white">{formatBlogTitle(content.slug)}</h1>
            </div>
        </Link>
    )
}
