export const sendTelegramFeedback = async (user:string,msg:string,type:string) => {
    const token = process.env.REACT_APP_TELEGRAM_KEY as string;
    const chatId = '6661187863'; // You need to find out your chat ID
    const message = 'New Feedback from '+user+'!\n\n'+type+'\n'+msg+'';

    const apiUrl = `https://api.telegram.org/bot${token}/sendMessage`;
    const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            chat_id: chatId,
            text: message,
        }),
    });

    const data = await response.json();
    console.log(data);
};
export const sendTelegramError = async (error:string) => {
    const token = process.env.REACT_APP_TELEGRAM_KEY as string;
    const chatId = '6661187863'; // You need to find out your chat ID
    const message = '‼️New Error‼️\n'+error;

    const apiUrl = `https://api.telegram.org/bot${token}/sendMessage`;
    const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            chat_id: chatId,
            text: message,
        }),
    });

    const data = await response.json();
    console.log(data);
}