import React, { useEffect, useState } from 'react'

export default function ProgressBar({type,items}:{type:string,items:any}) {
    const [done, setDone] = useState(0)
    const [procent, setProcent] = useState(0)
    const max = items.filter((n:any) => n).length;

    items=items.filter((n:any) => n)
    useEffect(()=>{
        // SECTION: PACKING
        if (items[0].item) {
            const count = items.filter(function(item:any) {
                return item.packed === true;
            });
            setDone(count.length);
        }
        else {
            const count = items.filter(function(item:any) {
                return item.checked === true;
            });
            setDone(count.length);
        }
    },[items])
    useEffect(()=>{
        if (done>0) {
            const procentTmp= Math.round(done / max * 100);
            setProcent(procentTmp)
        }
    },[done, max, procent])
    
    
    return (
        <div className='my-5 w-full flex flex-row items-center justify-between gap-2'>
            <p className='w-1/12'>{type}</p>
            <progress className={"progress w-10/12"+(procent===100?' progress-success':' progress-primary')} value={procent} max="100"></progress>
            <p className='w-1/12 text-right'>{procent}%</p>
        </div>
    )
}
