import { CalendarPlus } from 'lucide-react'
import moment from 'moment'
import React, { useContext } from 'react'
import { UserContext } from '../App';
import i18nIsoCountries from 'i18n-iso-countries';
import { useTranslation } from 'react-i18next';

export default function DateContainer({dataVacation}:{dataVacation:Vacations}) {
    const { t } = useTranslation();
    const userData = useContext(UserContext);

    const handleDownload = () => {
        let newEnd;
        if (dataVacation.date_start!==dataVacation.date_end) {
            const daysBeforeTmp = new Date(dataVacation.date_end);
            daysBeforeTmp.setDate(daysBeforeTmp.getDate() + 1);
            newEnd = daysBeforeTmp.getFullYear()+'-'+(daysBeforeTmp.getMonth() + 1).toString().padStart(2, '0')+'-'+daysBeforeTmp.getDate().toString().padStart(2, '0');
        }
        else {
            newEnd = dataVacation.date_end;
        }
        const icsMSG = `
BEGIN:VCALENDAR
VERSION:2.0
METHOD:REQUEST
CALSCALE:GREGORIAN
BEGIN:VEVENT
UID:${dataVacation.id}
X-ORGANIZER:contact@vacation-vision.com
SEQUENCE:${dataVacation.id}
STATUS:CONFIRMED
SUMMARY:${i18nIsoCountries.getName(dataVacation.country, userData.lang)}
DESCRIPTION:${dataVacation.description_name}
CLASS:PUBLIC
DTSTART:${dataVacation.date_start.replaceAll("-",'')}
DTEND:${newEnd.replaceAll("-",'')}
DTSTAMP:${dataVacation.date_start.replaceAll("-",'')}
URL;VALUE=URI:https://vacation-vision.com/home/${dataVacation.id}
END:VEVENT
END:VCALENDAR
`;
        const blob = new Blob([icsMSG], { type: 'text/calendar' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', dataVacation.description_name+'.ics');
        document.body.appendChild(link);
      
        link.click();
    }
    
    return (
        <div className='bg-base-300 p-3 rounded-lg my-5 flex flex-row justify-between gap-2 w-full sm:w-3/4 md:w-2/5'>
            <div className='flex flex-row justify-between gap-1 w-full'>
                <div className='flex flex-col gap-3'>
                    <div>
                        <p className='text-xs'>{t('from')}</p>
                        <p className='hidden md:flex lg:hidden'>📅 {moment(dataVacation.date_start).format('ll')}</p>
                        <p className='md:hidden lg:flex'>📅 {moment(dataVacation.date_start).format('LL')}</p>
                    </div>
                    <div>
                        <p className='text-xs'>{t('to')}</p>
                        <p className='hidden md:flex lg:hidden'>📅 {moment(dataVacation.date_end).format('ll')}</p>
                        <p className='md:hidden lg:flex'>📅 {moment(dataVacation.date_end).format('LL')}</p>
                    </div>
                </div>
                <div className='flex flex-col justify-between items-center'>
                    <button className='btn btn-sm btn-circle' onClick={handleDownload}><CalendarPlus size={16} /></button>
                </div>
            </div>
        </div>
    )
}
