import React, { useEffect } from 'react'
import config from '../../../config';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

// CHATGPT PROMPT TO GENERATE YOUR PRIVACY POLICY — replace with your own data 👇

// 1. Go to https://chat.openai.com/
// 2. Copy paste bellow
// 3. Replace the data with your own (if needed)
// 4. Paste the answer from ChatGPT directly in the <pre> tag below

// You are an excellent lawyer.

// I need your help to write a simple privacy policy for my website. Here is some context:
// - Website: 
// - Name: 
// - Description: An application responsible for help Personal Trainers and People that like to go to the gym improve their performance.
// - User data collected: name, email, phone, and payment information
// - Non-personal data collection: web cookies
// - Purpose of Data Collection: To know better our clients
// - Data sharing: we do not share the data with any other parties
// - Children's Privacy: we do not collect any data from children
// - Updates to the Privacy Policy: users will be updated by email
// - Contact information: 

// Please write a simple privacy policy for my site. Add the current date.  Do not add or explain your reasoning. Answer:
export default function Privacy() {
    const { t } = useTranslation();
    useEffect(() => {
        document.title = `${t('privacyPolicy')} | ${config.appName}`;
    }, [t]);
    return (
        <main className="max-w-xl mx-auto">
            <div className="p-5">
                <Link to="/" className="btn btn-ghost">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5" ><path fillRule="evenodd" d="M15 10a.75.75 0 01-.75.75H7.612l2.158 1.96a.75.75 0 11-1.04 1.08l-3.5-3.25a.75.75 0 010-1.08l3.5-3.25a.75.75 0 111.04 1.08L7.612 9.25h6.638A.75.75 0 0115 10z" clipRule="evenodd" /></svg>{" "}
                    {t('back')}
                </Link>
                <h1 className="text-3xl font-extrabold pb-6">{t('privacyPolicy')} {config.appName}</h1>
                {i18n.language==='de'?
                <pre className="leading-relaxed whitespace-pre-wrap" style={{ fontFamily: "Nunito" }} >
                    <p className="text-sm text-gray-500 mb-4">Zuletzt aktualisiert: 10. Oktober 2024</p>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">1. Bereitgestellte Dienste</h2>
                        <p>Vacation Vision ist eine Anwendung, die es ermöglicht Reisen zu planen. Benutzer können durch den Kauf eines Plans oder Abonnements auf verschiedene Funktionen zugreifen.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">2. Konto Registrierung</h2>
                        <p>Um unsere Dienste nutzen zu können, müssen Sie ein Konto registrieren. Sie erklären sich damit einverstanden, genaue und vollständige Informationen, einschließlich Ihres Namens, Ihrer E-Mail-Adresse, Adresse und Zahlungsdetails, bereitzustellen. Sie sind verantwortlich für die Wahrung der Vertraulichkeit Ihrer Kontoinformationen.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">3. Zahlung und Abonnement</h2>
                        <p>Durch den Kauf eines Plans oder Abonnements erhalten Sie Zugang zu verschiedenen Funktionen auf Vacation Vision. Alle Zahlungen sind endgültig, und Rückerstattungen werden nur gemäß unserer Rückerstattungsrichtlinie gewährt.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">4. Datenerfassung</h2>
                        <p>Wir erfassen personenbezogene Daten wie Ihren Namen, Ihre E-Mail-Adresse, Telefonnummer und Zahlungsinformationen, um unsere Dienste bereitzustellen. Außerdem sammeln wir nicht-personenbezogene Daten, wie z.B. Web-Cookies, um Ihre Erfahrung zu verbessern. Weitere Einzelheiten finden Sie in unserer <a href="/privacy-policy" className="text-blue-500 underline">Datenschutzerklärung</a>.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">5. Eigentum und Lizenz</h2>
                        <p>Vacation Vision behält sich alle Rechte, Titel und Interessen an der Anwendung und ihrem Inhalt vor. Durch den Kauf eines Plans oder Abonnements erhalten Sie eine eingeschränkte, nicht-exklusive, nicht übertragbare Lizenz zur Nutzung der von Vacation Vision bereitgestellten Funktionen.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">6. Anwendbares Recht</h2>
                        <p>Diese Nutzungsbedingungen unterliegen dem Recht der Bundesrepublik Deutschland und sind entsprechend auszulegen. Alle Streitigkeiten, die sich aus der Nutzung unserer Dienste ergeben, unterliegen der ausschließlichen Zuständigkeit der deutschen Gerichte.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">7. Änderungen der Bedingungen</h2>
                        <p>Wir behalten uns das Recht vor, diese Nutzungsbedingungen jederzeit zu aktualisieren oder zu ändern. Benutzer werden über wesentliche Änderungen per E-Mail informiert.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">8. Kontaktinformationen</h2>
                        <p>Wenn Sie Fragen oder Bedenken zu diesen Nutzungsbedingungen haben, kontaktieren Sie uns bitte unter <a href="mailto:contact@vacation-vision.com" className="text-blue-500 underline">contact@vacation-vision.com</a>.</p>
                    </section>
                    <footer className="mt-8 text-center text-gray-500">
                        <p>Vielen Dank, dass Sie Vacation Vision nutzen.</p>
                        <p>Das Vacation Vision Team</p>
                    </footer>
                </pre>
                :
                <pre className="leading-relaxed whitespace-pre-wrap" style={{ fontFamily: "Nunito" }} >
                    <p className="text-sm text-gray-500 mb-4">Last updated: October 10, 2024</p>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">1. Provided Services</h2>
                        <p>Vacation Vision is an application that allows users to plan trips. By purchasing a plan or subscription, users can access various features.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">2. Account Registration</h2>
                        <p>To use our services, you must register an account. You agree to provide accurate and complete information, including your name, email address, address, and payment details. You are responsible for maintaining the confidentiality of your account information.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">3. Payment and Subscription</h2>
                        <p>By purchasing a plan or subscription, you gain access to various features on Vacation Vision. All payments are final, and refunds are only granted in accordance with our refund policy.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">4. Data Collection</h2>
                        <p>We collect personal data such as your name, email address, phone number, and payment information to provide our services. We also collect non-personal data, such as web cookies, to improve your experience. For more details, please see our <a href="/privacy-policy" className="text-blue-500 underline">Privacy Policy</a>.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">5. Ownership and License</h2>
                        <p>Vacation Vision reserves all rights, title, and interest in the application and its content. By purchasing a plan or subscription, you receive a limited, non-exclusive, non-transferable license to use the features provided by Vacation Vision.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">6. Governing Law</h2>
                        <p>These terms of service are governed by and construed in accordance with the laws of the Federal Republic of Germany. Any disputes arising from the use of our services are subject to the exclusive jurisdiction of the German courts.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">7. Changes to Terms</h2>
                        <p>We reserve the right to update or change these terms of service at any time. Users will be notified of any significant changes via email.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">8. Contact Information</h2>
                        <p>If you have any questions or concerns about these terms of service, please contact us at <a href="mailto:contact@vacation-vision.com" className="text-blue-500 underline">contact@vacation-vision.com</a>.</p>
                    </section>
                    <footer className="mt-8 text-center text-gray-500">
                        <p>Thank you for using Vacation Vision.</p>
                        <p>The Vacation Vision Team</p>
                    </footer>
                </pre>
                }
            </div>
        </main>
    );
}


