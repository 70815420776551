import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
// @ts-ignore
import image1 from "./img/vacations.PNG";
// @ts-ignore
import image2 from "./img/vacation_details.PNG";
// @ts-ignore
import image3 from "./img/packing_lists.PNG";
// @ts-ignore
import image4 from "./img/todo_lists.PNG";
// @ts-ignore
import image5 from "./img/budget.PNG";
interface Feature {
    title: string;
    description: string;
    path?: string;
    alt: string
}


const Media = ({ feature }: { feature: Feature }) => {
    // const { path } = feature;
    const style = "rounded-lg aspect-squares sw-full _sm:w-[26rem]";
    const size = { width: 300, height: 300 };
    return (
        <img
            src={feature.path}
            className={`${style} object-cover object-center`}
            width={size.width}
            height={size.height}
            alt={feature.alt}
        />
    )
};

// An SEO-friendly accordion component including the title and a description (when clicked.)
const Item = ({index, feature, isOpen, setFeatureSelected}:{index: number; feature: Feature; isOpen: boolean; setFeatureSelected: () => void}) => {
    const accordion = useRef<any>(null);
    const { title, description } = feature;

    return (
        <li>
            <button className="relative flex gap-2 items-center w-full py-5 text-base font-medium text-left border-t md:text-lg border-base-content/10" onClick={(e) => { e.preventDefault(); setFeatureSelected(); }} aria-expanded={isOpen} >
                <span className={`flex-1 text-base-content ${isOpen ? "text-primary" : ""}`} >
                    <span className={`mr-2`}>{index + 1}.</span>
                    <h3 className="inline">{title}</h3>
                </span>
                <svg className={`flex-shrink-0 w-4 h-4 ml-auto fill-current ${isOpen ? "fill-primary" : "" }`} viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" >
                    <rect y="7" width="16" height="2" rx="1" className={`transform origin-center transition duration-200 ease-out ${isOpen && "rotate-180" }`} />
                    <rect y="7" width="16" height="2" rx="1" className={`transform origin-center rotate-90 transition duration-200 ease-out ${isOpen && "rotate-180 hidden" }`} />
                </svg>
            </button>

            <div ref={accordion} className={`transition-all duration-300 ease-in-out text-base-content-secondary overflow-hidden`} style={ isOpen ? { maxHeight: accordion?.current?.scrollHeight, opacity: 1 } : { maxHeight: 0, opacity: 0 } } >
                <div className="pb-5 leading-relaxed">{description}</div>
            </div>
        </li>
    );
};

// A component to display 2 to 5 features in an accordion.
// By default, the first feature is selected. When a feature is clicked, the others are closed.
const FeaturesAccordion = () => {
    const { t } = useTranslation();
    const [featureSelected, setFeatureSelected] = useState(0);
    const features = [
        {
            title: t('feature1'),
            description: t('feature11'),
            path:image1,
            alt: t('feature1'),
        },
        {
            title: t('feature2'),
            description: t('feature21'),
            path:image2,
            alt: t('feature2'),
        },
        {
            title: t('feature3'),
            description: t('feature31'),
            path:image3,
            alt: t('feature3'),
        },
        {
            title: t('feature4'),
            description: t('feature41'),
            path:image4,
            alt: t('feature4'),
        },
        // {
        //     title: t('feature5'),
        //     description: t('feature51'),
        //     path:image5,
        //     alt: t('feature5'),
        // },
        {
            title: t('feature6'),
            description: t('feature61'),
            path:image5,
            alt: t('feature6'),
        },
    ] as Feature[];
    return (
        <section className="py-24 md:py-32 space-y-24 md:space-y-32 max-w-7xl mx-auto bg-base-100" id="features" >
            <div className="px-8">
                <p className="font-medium text-sm text-accent mb-2">Features</p>
                <h2 className="font-extrabold text-4xl lg:text-6xl tracking-tight mb-12 md:mb-24">
                    {t('makeVacation')}
                    <span className="bg-neutral text-neutral-content px-2 md:px-4 ml-1 md:ml-1.5 leading-relaxed whitespace-nowrap">{t('easy')}</span>
                </h2>
                <div className=" flex flex-col-reverse lg:flex-row gap-12 md:gap-24">
                    <ul className="w-full">
                        {features.map((feature, i) => (
                            <Item key={feature.title} index={i} feature={feature} isOpen={featureSelected === i} setFeatureSelected={() => setFeatureSelected(i)} />
                        ))}
                    </ul>
                    <div className="flex justify-center w-full">
                        <Media feature={features[featureSelected]} key={featureSelected} />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default FeaturesAccordion;
