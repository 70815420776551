import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { Layout } from '../../../components/GeneralComponents'
import BlogCard from './BlogCard'
// @ts-ignore
// Create a context to require all markdown files
const markdownFiles = require.context('../../../blogposts', false, /\.md$/);

export default function BlogMain() {
    const [posts, setPosts] = useState<{ slug: string; content: string }[]>([]);

    useEffect(() => {
        const loadPosts = async () => {
            const loadedPosts = await Promise.all(
                markdownFiles.keys().map(async (filename:any) => {
                    const slug = filename.replace('./', '').replace('.md', ''); // Create a slug from the filename
                    const content = await markdownFiles(filename); // Import the content of each markdown file
                    return { slug, content: await fetch(content.default).then((res) => res.text()) }; // Load markdown content
                })
            );
            setPosts(loadedPosts.reverse());
        };

        loadPosts();
    }, []);
    return (
        <Layout>
            <Helmet>
                <title>Blog | Vacation Vision</title>
            </Helmet>
            <h1 className="text-3xl md:text-4xl font-extrabold">Blog</h1>
            <div className="flex flex-wrap">
                {posts.map((post) => {
                    if (post.slug.substring(0,1)==='_') return <></>;
                    return <BlogCard key={post.slug} content={post} />
                })}
            </div>
        </Layout>
    )
}
