async function fetchImages(searchTerm:string) {
    const pexelsKey:any = process.env.REACT_APP_PEXELS_KEY;
    const result = await fetch(`https://api.pexels.com/v1/search?query=${searchTerm}&per_page=5&orientation=landscape&size=medium`, {
        headers: {
            Authorization: pexelsKey,
        }
    });
    const data = await result.json();

    const images = data.photos.map((photo:any) => {
        return {url:photo.src.landscape,owner:photo.photographer,owner_url:photo.photographer_url}
    })
    return images
}

export default fetchImages