
// import { TextField } from "@mui/material";
import { Trash } from "lucide-react";
import { useTranslation } from "react-i18next";
// import { FaTrash } from "react-icons/fa";
import { v4 } from "uuid";

// interface InputItemLinks {
//     id: string;
//     description: string;
//     link: string;
// }

export default function VacationLinks({links,setLinks}:{links:Array<InputItemLinks>,setLinks:any}) {
    const { t } = useTranslation();
    const handleDescriptionChange = (id: string, newText: string) => {
        setLinks((prevTodos:InputItemLinks[]) =>
            prevTodos.map(input =>
                input.id === id ? { ...input, description: newText } : input
            )
        );
    };
    const handleLinkChange = (id: string, newText: string) => {
        setLinks((prevTodos:InputItemLinks[]) =>
            prevTodos.map(input =>
                input.id === id ? { ...input, link: newText } : input
            )
        );
    };
    const handleAddNewInput = () => {
        const newId = v4()
        setLinks((prevTodos:InputItemLinks[]) => [...prevTodos, { id: newId, description: '', link: '' }]);
    };
    const handleDeleteTodo = (id: string) => {
        setLinks((prevTodos:InputItemLinks[]) => prevTodos.filter(link => link.id !== id));
    };

    return (
        <div className="flex flex-col items-start my-s2 w-full">
            <div className="flex items-center gap-3">
                <label className="" >{t("saveForLater")}</label>
                <button className="btn btn-circle btn-sm btn-primary" type="button" onClick={handleAddNewInput}>+</button>
            </div>
            {links?.map(link => {
                return <div className="w-full my-4 flex justify-between items-end gap-2" key={link.id}>
                    <div className="flex flex-col md:flex-row gap-2 w-full">
                        <label className="form-control w-full md:w-4/12">
                            <div className="pl-1"><span className="label-text-alt">{t('name')}</span></div>
                            {/* <input required name="date_end" className="input input-bordered input-sm" type="date" value={vacationChange?.date_end??today} min={vacationChange?.date_start??today} onChange={handleVacationChange} /> */}
                            <input className="input input-bordered input-sm w-full" defaultValue={link.description} onChange={(e) => handleDescriptionChange(link.id, e.target.value)} />
                        </label>
                        <label className="form-control w-full md:w-8/12">
                            <div className="pl-1"><span className="label-text-alt">{t('url')}</span></div>
                            <input className="input input-bordered input-sm w-full" defaultValue={link.link} onChange={(e) => handleLinkChange(link.id, e.target.value)} />
                        </label>
                        {/* <input required name="description_name" className="input input-bordered input-sm" type="text" value={vacationChange?.description_name??''} onChange={handleVacationChange} /> */}
                    </div>
                    <button className="btn btn-sm btn-circle btn-error" onClick={()=>handleDeleteTodo(link.id)}><Trash size={18} /></button>
                </div>
            })}
            <div className="w-full flex justify-center">
            </div>
        </div>
    )
}
