import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// @ts-ignore
import LanguageDetector from 'i18next-browser-languagedetector';
// import HttpBackend from "i18next-http-backend"; // *** added this ***

i18n
.use(LanguageDetector)
.use(initReactI18next)
.init({
    // debug: true,
    fallbackLng: 'en',
    supportedLngs: ["en", "es", "de", "en-GB", "de-DE"], // *** added this ***
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
        en: {
            translation: {
                // landingpage
                planDreamVacation:"Plan Your Dream Vacation",
                allInOne:"Your All-in-One Vacation Planner for Seamless Travel Adventures",
                startNow:"Get started",
                makeVacation:"Make your vacation",
                easy:"easy",
                feature1:"Vacation overview",
                feature11:"Manage all your upcoming vacations, see upcoming and past vacations and effortlessly plan your itinerary, ensuring no moment of your trip goes unplanned",
                feature2:"Vacation details",
                feature21:"Up-to-date weather forecasts to help you pack right and make the most of your vacation. Centralise all your travel resources from booking confirmations to local guides in one place with vacation notes",
                feature3:"Packing lists",
                feature31:"Create a packing checklists tailored to your destination and activities or create templates for the things you always bring",
                feature4:"Todo lists",
                feature41:"Stay organised with dynamic to-do lists so you don’t forget visa applications or create templates for the crucial things like booking flights and accommodation",
                feature5:"Itinerary",
                feature51:"Visit iconic landmarks to immerse yourself in the rich history and culture of the destination. Indulge in a gastronomic journey by trying local delicacies at recommended restaurants. Got too many recommendation? Better write them down. Never miss any highlight ever again",
                feature6:"Budget",
                feature61:"Have an eye on your expenses. Don't overspend and always know what you can affort.",
                footerText:"Your All-in-One Vacation Planner for Seamless Travel Adventures",
                howitWorks:"How it works",
                tos:"Terms & Services",
                imprint:"Imprint",
                // general
                close:"Close",
                cancel:"Cancel",
                notes:"Notes",
                yourName:"Your name",
                save:"Save",
                saved:"Saved",
                loading:"loading..",
                logout:"Logout",
                logoutAsk:"Are you sure you want to logout?",
                edit:"Edit",
                hide:"Hide completed",
                show:"Show completed",
                template:"Template",
                yes:"yes",
                no:"no",
                // dashboard
                welcome:"Welcome",
                addInfo:"Please complete some informations",
                goSettings:"Go to settings",
                upcoming:"Up Next",
                newDestination:"New Destination",
                futureTrips:"Future Trips",
                emptyTodo:"click 'New Destination' to start",
                emptyUpcoming:"🏴‍☠️ No upcoming vacations",
                emptyUpcomingPlan:"Plan one now",
                nextVacation:"Next vacation",
                currentVacation:"Current vacation",
                pastVacation:"Past vacation",
                pastVacations:"Past vacations",
                searchVacations:"Search for vacations...",
                // navbar
                home:"Home",
                dashboard:"Dashboard",
                destinations:"Destinations",
                settings:"Settings",
                feedback:"Feedback",
                // budget
                budget:"Budget",
                yourBudget:"Your Budget",
                left:"left",
                addBudget:"Add Budget",
                // save links
                savedLink:"Hotel, Maps, etc.",
                saveForLater:"Save for later",
                // new / edit
                nameVacation:"Name your vacation",
                nameVacationPlace:"How do you want to name your trip?",
                startVacation:"Start date",
                endVacation:"End date",
                countryVacation:"Country",
                stateVacation:"State",
                stateVacationPlace:"State (optional)",
                cityVacation:"City",
                cityVacationPlace:"City (optional)",
                commuteVacation:"Type of commute",
                commuteVacationPlace:"Commute",
                commuteVacationAirplane:"Airplane",
                commuteVacationBus:"Bus",
                commuteVacationTrain:"Train",
                commuteVacationCar:"Car",
                commuteVacationIdk:"Not sure yet",
                packingListTemplate:"Packing List Template",
                todoListTemplate:"Todo List Template",
                createTodoTemplate:'Create your todo templates in settings',
                createPackingTemplate:'Create your packing templates in settings',
                useItinerary:"Use itinerary",
                // map
                countriesVisited:"Countries you visited",
                map:"Map",
                visited:"Visited: ",
                planned:"Planned: ",
                // feedback
                support:"Support",
                yourNamePlace:"What is your name?",
                feedbackType:"Type of feedback",
                feedbackTypeBug:"Bug",
                feedbackTypeFeature:"Feature Request",
                feedbackTypeQuestion:"Question",
                feedbackTypeOther:"Other",
                select:"Select...",
                message:"Message",
                send:"Send",
                feedbackSent:"Thank you for your feedback",
                // settings
                userSettings:"User Settings",
                email:"Email",
                deleteTemplateMsg:"Are you sure, you want to delete the template? This can not be undone!",
                chooseTemplate:"choose template",
                newTemplateName:"click to change name",
                lang:"Language",
                en:"english",
                de:"german",
                es:"spanish",
                selectPassport:"select your passport",
                passport:"Passport",
                passportExplain:"You will get visa informations, please do your own research anyway",
                currency:'Currency',
                delete:'delete',
                // visa
                noVisaInfo:"no informations",
                // login
                signIn:"Sign In",
                signInPass:"Your password",
                signInEmail:"Your Email address",
                signUpPass1:"Set your password",
                signUpPass2:"Repeat your password",
                switchSignUp:"Don't have an account? Sign up",
                signUp:"Sign Up",
                switchSignIn:"Already have an account? Sign in",
                agreeTo: "By signing up you agree to the ",
                termsOfService:"Terms of Service",
                privacyPolicy:"Privacy Policy",
                forgotPW:"Forgot your password?",
                noForgotPW:"Remember it?",
                passChanged:"Password changed",
                resetPass:"Reset Password",
                // vacations
                upcomingVacations:"Your upcoming Vacations",
                addNew:"Add new",
                today:"Today",
                perDay:"per day",
                tomorrow:"Tomorrow",
                dayAgo:"1 day ago",
                daysAgo:"days ago",
                inDays:"in {{days}} days",
                vacationCancel:"Cancel Vacation",
                vacationCancelMsg:"Are you sure, you want to cancel the vacation? This can not be undone!",
                vacationCancelInfo:"Please make sure you cancel hotels, flights etc.",
                maxVacations:"You reached the maximum vacations with your plan.",
                maxVacationsLow:"Or cancel one of your vacation.",
                itinerary:"Daily planner",
                emptyDay:"nothing planned yet",
                // vacation single
                overview:"Overview",
                packingList:"Packing List",
                bag1:"Bag 1",
                bag2:"Bag 2",
                bag3:"Bag 3",
                bag4:"Bag 4",
                newBag:"new bag",
                todos:"Todos",
                travelDates:"TRAVEL DATES: ",
                from:"From",
                to:"To",
                allPacked:"All packed",
                allDone:"All done",
                // weather
                weatherForecast:"Weather forecast",
                sundayShort:"Sun",
                mondayShort:"Mon",
                tuesdayShort:"Tue",
                wednesdayShort:"Wed",
                thursdayShort:"Thur",
                fridayShort:"Fri",
                saturdayShort:"Sat",
                sunday:"Sunday",
                monday:"Monday",
                tuesday:"Tuesday",
                wednesday:"Wednesday",
                thursday:"Thursday",
                friday:"Friday",
                saturday:"Saturday",
                day1:"st",
                day2:"nd",
                day3:"rd",
                day4:"th",
                // Intinerary 
                createAI:"Create with AI",
                upgradeForCreateAI:"Upgrade for AI create",
                // gold
                upgradeNow:"upgrade now",
                planHead:"Make the most out of your vacation",
                monthly:"monthly",
                month:"month",
                yearly:"yearly",
                year:"year",
                per:"per",
                tenMonth:"pay only 10 month",
                tierAlready:"You are ",
                tierAlready2:" already",
                changeIn:"You can change your plan in",
                templateUpgrade:"Please upgrade to use templates",
                // newsletter
                unsubscribeSuccess:"You are unsubscribed",
                unsubscribeError:"Something went wrong. Please try again or use Feedback form.",
                newsletterSub:"Subscribe to newsletter",
                newsletterUnSub:"Unsubscribe from newsletter",
                newsSub:"Subscribe",
                newsUnSub:"Unsubscribe",
            }
        },
        de: {
            translation: {
                // landingpage
                planDreamVacation:"Plane deinen Traumurlaub",
                allInOne:"Dein All-in-One Reiseplaner für nahtlose Reiseabenteuer",
                startNow:"Los geht's",
                makeVacation:"Mach deine Reise",
                easy:"einfach",
                feature1: "Urlaubsübersicht",
                feature11: "Verwalte all deine bevorstehenden Urlaube, sieh anstehende und vergangene Reisen und plane mühelos deine Reiseroute, damit kein Moment deines Urlaubs ungenutzt bleibt",
                feature2: "Urlaubsdetails",
                feature21: "Aktuelle Wettervorhersagen, um die beim richtigen Packen zu helfen und das Beste aus deinem Urlaub herauszuholen. Zentralisiere alle deine Reiseunterlagen, von Buchungsbestätigungen bis hin zu lokalen Reiseführern, an einem Ort mit deinen Notizen",
                feature3: "Packlisten",
                feature31: "Erstelle eine Packliste, die auf dein Reiseziel und deine Aktivitäten zugeschnitten ist, oder erstelle Vorlagen für Dinge, die du immer mitnimmst",
                feature4: "To-do-Listen",
                feature41: "Bleibe organisiert mit dynamischen To-do-Listen, damit du keine Visa-Anträge vergisst, oder erstelle Vorlagen für die wichtigen Dinge wie das Buchen von Flügen und Unterkünften",
                feature5: "Reiseroute",
                feature51: "Besuche ikonische Sehenswürdigkeiten, um in die reiche Geschichte und Kultur des Reiseziels einzutauchen. Genieße eine gastronomische Reise, indem du lokale Delikatessen in empfohlenen Restaurants probierst. Zu viele Empfehlungen? Schreibe sie besser auf. Verpasse nie wieder ein Highlight",
                feature6: "Budget",
                feature61: "Behalte deine Ausgaben im Auge. Gib nicht zu viel aus und weiß immer, was du dir leisten kannst.",
                footerText:"Dein All-in-One Reiseplaner für nahtlose Reiseabenteuer",
                howitWorks:"So funktioniert es",
                tos:"Nutzungsbedingungen",
                imprint:"Impressum",
                // general
                close:"Schließen",
                cancel:"Abbrechen",
                notes:"Notizen",
                yourName:"Dein name",
                save:"Speichern",
                saved:"Gespeichert",
                loading:"lädt..",
                logout:"Ausloggen",
                logoutAsk:"Bist du sicher, dass du dich ausloggen möchtest?",
                edit:"Bearbeiten",
                hide:"Erledigte ausblenden",
                show:"Erledigte einblenden",
                template:"Vorlagen",
                yes:"ja",
                no:"nein",
                // dashboard
                welcome:"Willkommen",
                addInfo:"Bitte vervollständige einige Informationen",
                goSettings:"Gehe zu Einstellungen",
                upcoming:"Als nächstes",
                newDestination:"Neues Reiseziel",
                futureTrips:"Zukünftige Trips",
                emptyTodo:"klicke 'Neues Reiseziel' um zu starten",
                emptyUpcoming:"🏴‍☠️ Keine zukünftigen Reisen",
                emptyUpcomingPlan:"Jetzt planen",
                nextVacation:"Nächste Reise",
                currentVacation:"Aktuelle Reise",
                pastVacation:"Abgeschlossene Reise",
                pastVacations:"Abgeschlossene Reisen",
                searchVacations:"Nach Trips suchen...",
                // navbar
                home:"Home",
                dashboard:"Dashboard",
                destinations:"Reiseziele",
                settings:"Einstellungen",
                feedback:"Feedback",
                // budget
                budget:"Budget",
                yourBudget:"Dein Budget",
                left:"übrig",
                addBudget:"Budget hinzufügen",
                // save links
                savedLink:"Hotel, Maps, etc.",
                saveForLater:"Für später speichern",
                // new / edit
                nameVacation:"Name für deine Reise",
                nameVacationPlace:"Wie soll deine Reise heißen?",
                startVacation:"Startdatum",
                endVacation:"Enddatum",
                countryVacation:"Land",
                stateVacation:"Staat",
                stateVacationPlace:"Staat (optional)",
                cityVacation:"Stadt",
                cityVacationPlace:"Stadt (optional)",
                commuteVacation:"Art der Anreise",
                commuteVacationPlace:"Anreise",
                commuteVacationAirplane:"Flugzeug",
                commuteVacationBus:"Bus",
                commuteVacationTrain:"Zug",
                commuteVacationCar:"Auto",
                commuteVacationIdk:"Noch nicht sicher",
                packingListTemplate:"Packliste Vorlage",
                todoListTemplate:"Todo Liste Vorlate",
                createTodoTemplate:'Erstelle dein Todolisten Templates in den Einstellungen',
                createPackingTemplate:'Erstelle dein Packlisten Templates in den Einstellungen',
                useItinerary:"Tagesplaner benutzen",
                // map
                countriesVisited:"Länder die du besucht hast",
                map:"Karte",
                visited:"Bereist: ",
                planned:"Geplant: ",
                // feedback
                support:"Support",
                yourNamePlace:"Wie ist dein Name?",
                feedbackType:"Feedbacktyp",
                feedbackTypeBug:"Bug",
                feedbackTypeFeature:"Funktion",
                feedbackTypeQuestion:"Frage",
                feedbackTypeOther:"Sonstiges",
                select:"Auswählen...",
                message:"Nachricht",
                send:"Senden",
                feedbackSent:"Danke für dein Feedback",
                // settings
                userSettings:"User Einstellungen",
                email:"Email",
                deleteTemplateMsg:"Bist du sicher, dass du diese Vorlage löschen möchtest? Das kann nicht rückgängig gemacht werden!",
                chooseTemplate:"Wähle Vorlage",
                newTemplateName:"klicken um Namen zu ändern",
                lang:"Sprache",
                en:"englisch",
                de:"deutsch",
                es:"spanisch",
                selectPassport:"wähle deinen Reisepass",
                passport:"Reisepass",
                passportExplain:"Du bekommst Visa Infos, bitte suche trotzdem selbst nach aktuellen Infos",
                currency:'Währung',
                delete:'löschen',
                // visa
                noVisaInfo:"Keine Informationen",
                // login
                signIn:"Anmelden",
                signInPass:"Dein Passwort",
                signInEmail:"Deine Email Adresse",
                signUpPass1:"Erstelle dein Passwort",
                signUpPass2:"Wiederhole dein Passwort",
                switchSignUp:"Du hast noch keinen Account? Registrieren",
                signUp:"Registrieren",
                switchSignIn:"Du hast schon einen Account? Anmelden",
                agreeTo: "Mit der Registrierug akzeptierst du die ",
                termsOfService:"Nutzungsbedingungen",
                privacyPolicy:"Datenschutzrichtlinie",
                forgotPW:"Passwort vergessen?",
                noForgotPW:"Wieder eingefallen?",
                passChanged:"Passwort geändert",
                resetPass:"Passwort zurücksetzen",
                // vacations
                upcomingVacations:"Deine zukünftigen Reisen",
                addNew:"Neu hinzufügen",
                today:"Heute",
                perDay:"pro Tag",
                tomorrow:"Morgen",
                dayAgo:"1 day ago",
                daysAgo:"days ago",
                inDays:"in {{days}} Tagen",
                vacationCancel:"Reise stornieren",
                vacationCancelMsg:"Bist du sicher, dass du die Reise stornieren möchtest? Das kann nicht rückgängig gemacht werden!",
                vacationCancelInfo:"Bitte stelle sicher das du Hotels, Flüge etc. stornierst.",
                maxVacations:"Du hast die maximale Anzahl an Reisen erreicht.",
                maxVacationsLow:"Oder storniere eine deiner Reisen.",
                itinerary:"Tagesplaner",
                emptyDay:"noch nichts geplant",
                // vacation single
                overview:"Übersicht",
                packingList:"Packliste",
                bag1:"Tasche 1",
                bag2:"Tasche 2",
                bag3:"Tasche 3",
                bag4:"Tasche 4",
                newBag:"neue Tasche",
                todos:"Todos",
                travelDates:"REISEDATUM: ",
                from:"Von",
                to:"Bis",
                allPacked:"Alles gepackt",
                allDone:"Alles erledigt",
                // weather
                weatherForecast:"Wetterbericht",
                sundayShort:"So",
                mondayShort:"Mo",
                tuesdayShort:"Di",
                wednesdayShort:"Mi",
                thursdayShort:"Do",
                fridayShort:"Fr",
                saturdayShort:"Sa",
                sunday:"Sonntag",
                monday:"Montag",
                tuesday:"Dienstag",
                wednesday:"Mittwoch",
                thursday:"Donnerstag",
                friday:"Freitag",
                saturday:"Samstag",
                day1:".",
                day2:".",
                day3:".",
                day4:".",
                // Intinerary 
                createAI:"Mit AI erstellen",
                upgradeForCreateAI:"Upgraden für AI Erstellung",
                // gold
                upgradeNow:"jetzt upgraden",
                planHead:"Mach das Beste aus deinen Reisen",
                monthly:"monatlich",
                month:"Monat",
                yearly:"jährlich",
                year:"Jahr",
                per:"pro",
                tenMonth:"pay only 10 month",
                tierAlready:"Du bist bereits ",
                tierAlready2:"",
                changeIn:"Du kannst deinen Plan ändern in",
                templateUpgrade:"Bitte upgraden um Templates zu nutzen",
                // newsletter
                unsubscribeSuccess:"Du bist abgemeldet",
                unsubscribeError:"Das hat nicht geklappt. Bitte versuche es erneut oder nutze das Feedback Formular.",
                newsletterSub:"Subscribe to newsletter",
                newsletterUnSub:"Unsubscribe from newsletter",
                newsSub:"Subscribe",
                newsUnSub:"Unsubscribe",

            }
        },
        es: {
            translation: {
                // general
                close:"Cerrar",
                cancel:"Cancelar",
                notes:"Notas",
                yourName:"Tu nombre",
                save:"Guardar",
                saved:"Saved",
                loading:"Cargando..",
                logout:"Salir",
                logoutAsk:"Estas seguro que quieres salir?",
                edit:"Editar",
                hide:"Ocultar completados",
                show:"Mostrar completados",
                template:"Plantilla",
                yes:"sí",
                no:"no",
                // dashboard
                welcome:"Bienvenido",
                addInfo:"Por favor complete algunas informaciones",
                goSettings:"Ir a herramientas",
                upcoming:"Próximamente...",
                newDestination:"Nuevo destino",
                futureTrips:"Future Trips",
                emptyTodo:"Clic en 'Nuevo destino' para empezar",
                emptyUpcoming:"🏴‍☠️ No hay vacaciones próximas",
                emptyUpcomingPlan:"Planear ahora",
                nextVacation:"Siguiente vacación ",
                currentVacation:"Vacación actual",
                pastVacation:"Vacación pasada",
                pastVacations:"Vacaciónes pasadas",
                // navbar
                home:"Home",
                dashboard:"Panel",
                destinations:"Destinos",
                settings:"Herramientas",
                feedback:"Comentario",
                // budget
                budget:"Presupuesto",
                yourBudget:"Tu presupuesto",
                left:"Sobrante",
                addBudget:"Add Budget",
                // save links
                savedLink:"Hotel, Mapas, etc.",
                saveForLater:"Guardar para después",
                // new / edit
                nameVacation:"Nombre de tus vacaciones",
                nameVacationPlace:"Cómo quieres nombrar tu viaje?",
                startVacation:"Fecha de comienzo",
                endVacation:"Fecha final",
                countryVacation:"País",
                stateVacation:"Estado",
                stateVacationPlace:"Estado (opcional)",
                cityVacation:"Ciudad",
                cityVacationPlace:"Ciudad (optional)",
                commuteVacation:"Tipo de transportación",
                commuteVacationPlace:"Transportación",
                commuteVacationAirplane:"Avión",
                commuteVacationBus:"Autobus",
                commuteVacationTrain:"Tren",
                commuteVacationCar:"Carro",
                commuteVacationIdk:"Aún no estoy seguro",
                packingListTemplate:"Packing List Template",
                todoListTemplate:"Todo List Template",
                createTodoTemplate:'Create your todo templates in settings',
                createPackingTemplate:'Create your packing templates in settings',
                // map
                countriesVisited:"Countries you visited",
                map:"Map",
                visited:"Visited: ",
                planned:"Planned: ",
                // feedback
                support:"Support",
                yourNamePlace:"Cómo te llamas?",
                feedbackType:"Tipo de comentario",
                feedbackTypeBug:"Bug",
                feedbackTypeFeature:"Solicitud de función",
                feedbackTypeQuestion:"Pregunta",
                feedbackTypeOther:"Otra",
                select:"Seleccionar...",
                message:"Mensaje",
                send:"Enviar",
                feedbackSent:"Thank you for your feedback",
                // settings
                userSettings:"Herramientas de usuario",
                email:"Correo electrónico ",
                deleteTemplateMsg:"Estas seguro que quieres eliminar esta plantilla? Esta acción no se podrá deshacer!",
                chooseTemplate:"Elige plantilla",
                newTemplateName:"Clic para cambiar tu nombre",
                lang:"Lenguaje",
                en:"inglés",
                de:"Alemán",
                es:"Español",
                selectPassport:"select your passport",
                passport:"Passport",
                passportExplain:"You will get visa informations, please do your own research anyway",
                currency:'Currency',
                // visa
                noVisaInfo:"no informations",
                // login
                signIn:"Ingresar",
                signInPass:"Tu contraseña",
                signInEmail:"Tu correo electrónico ",
                signUpPass1:"Set your password",
                signUpPass2:"Repeat your password",
                switchSignUp:"Aún no tienes cuenta? Crea una ahora",
                signUp:"Crear una cuenta",
                switchSignIn:"Ya tienes una cuenta? Ingresa ahora",
                agreeTo: "Al registrarte estás de acuerdo con los ",
                termsOfService:"Terminus de servicio",
                privacyPolicy:"Política de privacidad",
                forgotPW:"Olvidaste tu contraseña??",
                noForgotPW:"Recordar?",
                passChanged:"Password changed",
                resetPass:"Reset Password",
                // vacations
                upcomingVacations:"Tus próximas vacaciones",
                addNew:"Agregar nueva",
                today:"Hoy",
                perDay:"por día",
                tomorrow:"Mañana",
                dayAgo:"Hace un día",
                daysAgo:"days ago",
                inDays:"en {{days}} días",
                vacationCancel:"Cancelar vacaciones",
                vacationCancelMsg:"Estas seguro que quieres cancelar esta vacación? Esta acción no se podrá deshacer!",
                vacationCancelInfo:"Por favor cerciora cancelar tus hoteles, vuelos, etc.",
                maxVacations:"Haz alcanzado las máximas vacaciones en tu plan.",
                maxVacationsLow:"Or cancel one of your vacation.",
                itinerary:"Daily planner",
                emptyDay:"nothing planned yet",
                // vacation single
                overview:"Overview",
                packingList:"Lista de equipaje",
                todos:"Lista de quehaceres",
                travelDates:"FECHA DE VIAJES: ",
                from:"From",
                to:"To",
                allPacked:"All packed",
                allDone:"All done",
                // weather
                weatherForecast:"Pronóstico del clima",
                sundayShort:"D",
                mondayShort:"L",
                tuesdayShort:"M",
                wednesdayShort:"X",
                thursdayShort:"J",
                fridayShort:"V",
                saturdayShort:"S",
                day1:"er",
                day2:"do",
                day3:"ero",
                day4:"to",
                // Intinerary 
                createAI:"Create with AI",
                upgradeForCreateAI:"Upgrade for AI create",
                // gold
                upgradeNow:"Mejora tu plan ahora",
                planHead:"Aprovecha al máximo tus vacaciones",
                monthly:"Mensual",
                month:"mes",
                yearly:"anual",
                year:"año",
                per:"cada",
                tenMonth:"Paga solo 10 meses",
                tierAlready:"You are ",
                tierAlready2:" already",
                changeIn:"You can change your plan in",
                templateUpgrade:"Please upgrade to use templates",
                // newsletter
                unsubscribeSuccess:"You are unsubscribed",
                unsubscribeError:"Something went wrong. Please try again or use Feedback form.",
                newsletterSub:"Subscribe to newsletter",
                newsletterUnSub:"Unsubscribe from newsletter",
                newsSub:"Subscribe",
                newsUnSub:"Unsubscribe",
            }
        }
    }
});

export default i18n;