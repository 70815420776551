import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Country, State, City }  from 'country-state-city';
import { useTranslation } from 'react-i18next';
import i18nIsoCountries from 'i18n-iso-countries';
import 'moment/locale/de';
import 'moment/locale/en-gb';
import { UserContext } from '../App';
import { getTier, sendError, today } from '../utils/functions';
import supabase from '../utils/supabase';
import VacationLinks from './VacationLinks';
import VacationFormTemplates from './VacationFormTemplates';
import { Loading } from './GeneralComponents';
import Select from 'react-select'

i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));
i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/de.json"));

export default function VacationEditNew({vacation,todos,templatesTodos,packing,templatesPacking}:{vacation?:VacationUser, todos?:Array<TodoItem>, templatesTodos:Templates,packing?:Array<PackingItem>,templatesPacking:Templates}) {
    const { t } = useTranslation();
    const [,,userData] = useContext(UserContext);
    const navigate = useNavigate()
    const [todosList, setTodosList] = useState<TodoItem[]>(todos ?? []);
    const [packingList, setPackingList] = useState<PackingItem[]>(packing ?? []);
    const [loading, setLoading] = useState(false)
    const [linksList, setLinksList] = useState<InputItemLinks[]>(vacation?.saved_links ?? []);
    const [itinerary, setItinerary] = useState(vacation?.itinerary[0]===false?false:true)
    const oldItinerary = vacation?.itinerary[0]===false?[]:vacation?.itinerary;
    
    const [vacationChange, setVacationChange] = useState<Vacations>(vacation)
    const [hasChanged, setHasChanged] = useState(false)
    const [countriesList, setCountriesList] = useState<any>()
    const [citiesList, setCitiesList] = useState<labelAndValue[]>([{label:'',value:''}])
    const [statesList, setStatesList] = useState<labelAndValue[]>([{label:'',value:''}])
    
    const [seeCitiesList, setSeeCitiesList] = useState(false)
    const [seeStatesList, setSeeStatesList] = useState(false)

    useEffect(()=>{
        if (!hasChanged) return;
        const handleOnBeforeUnload = (event:BeforeUnloadEvent) => {
            event.preventDefault();
            return (event.returnValue='');
        }
        window.addEventListener('beforeunload',handleOnBeforeUnload,{capture:true});
        return () => {
            window.removeEventListener('beforeunload',handleOnBeforeUnload,{capture:true});
        }
    },[hasChanged])

    useEffect(()=>{
        if (userData) {
            setCountriesList(Country.getAllCountries().map(country=>( {label:i18nIsoCountries.getName(country.isoCode, userData.lang),value:country.isoCode} )))
        }
    },[userData])

    const handleOnChange = () => {
        setHasChanged(true);
    }

    const cancelEdit = () => { 
        if (vacation) navigate('/home/'+vacation.id)
        else navigate('/home')
    }
    const saveCountry = (country:string)=> {
        // setNewCountry(country)
        const cities:any = City.getCitiesOfCountry(country); 
        if (cities.length>=5000) {
            setSeeCitiesList(false);
            loadStates(country);
        }
        else if (cities.length===0) { setSeeCitiesList(false); }
        else {
            setSeeStatesList(false);
            loadCities(country,true);
        }
        handleOnChange();
    }
    const loadStates = (country:string)=> {
        const states:any = State.getStatesOfCountry(country); 
        if (states) {
            const val = states.map((state:any) => ({label:state.name,value:state.isoCode}))
            setStatesList(val)
            setSeeStatesList(true);
        }
        // setNewState('')
    }
    const loadCities = (e:any,country?:boolean)=> {
        let cities:any;
        if (country) {
            cities = City.getCitiesOfCountry(e); 
        }
        else {
            cities = City.getCitiesOfState(vacationChange.country,e); 
            // setNewState(e)
        }
        if (cities) {
            const val = cities.map((city:any) => ({label:city.name,value:city.name}))
            if (val.length>0) {
                setCitiesList(val)
                setSeeCitiesList(true);
            }
        }
        // setNewCity('')
    }
    const saveFormVacation = async (e:any) => {
        e.preventDefault()
        
        setHasChanged(false);
        setLoading(true)
        if (userData) {
            if (vacation) {
                const { data, error } = await supabase.from('vacations').update({ 
                    ...vacationChange,
                    user_id: userData.id,
                    todos:todosList,
                    packing_list:packingList,
                    saved_links:linksList,
                    itinerary:itinerary?oldItinerary:[false]
                })
                .eq('id', vacation.id)
                .select()
                if (data) { navigate('/home/'+vacation.id) }
                else { sendError(error) }
            }
            else {
                const { data, error } = await supabase.from('vacations').insert({ 
                    ...vacationChange,
                    user_id: userData.id,
                    todos:todosList,
                    packing_list:packingList,
                    saved_links:linksList,
                    itinerary:itinerary?oldItinerary:[false]
                })
                .select()
                if (data) { navigate('/home/'+data[0].id) }
                else { sendError(error) }
            }
        }
        setLoading(false)
    }
    const handleVacationChange = (e:any) => {
        setVacationChange({
            ...vacationChange,
            [e.target.name]: e.target.value
        });
        if (e.target.name==="country") saveCountry(e.target.value);
        if (e.target.name==="state") loadCities(e.target.value);
    }
    const handleCityChange = (e:any) => {
        setVacationChange({
            ...vacationChange,
            city: e.value
        });
    }
    const handleStateChange = (e:any) => {
        setVacationChange({
            ...vacationChange,
            state: e.value
        });
        loadCities(e.value);
    }
    const handleCountryChange = (e:any) => {
        setVacationChange({
            ...vacationChange,
            country: e.value
        });
        saveCountry(e.value)
    }
    const handlePackingTemplateChange = (e:any) => {
        setPackingList(e);
        handleOnChange();
    }
    const handleTodoTemplateChange = (e:any) => {
        setTodosList(e);
        handleOnChange();
    }
    const handleItineraryChange = (e:any) => {
        setItinerary(!itinerary)
    }

    return userData&&countriesList ? (
        <>
            <h1 className="text-3xl md:text-4xl font-extrabold">{(vacation)?t("edit"):t('addNew')}</h1>
            <form className="flex flex-col items-center w-full bg-base-200 p-3 rounded-lg" onSubmit={saveFormVacation} onChange={handleOnChange}>
                <div className='flex flex-col md:flex-row gap-3 justify-between my-s5 w-full'>
                    <div className='flex flex-col w-full md:w-96s gap-3'>
                        <label className="form-control w-full">
                            <div className="pl-1"><span className="label-text-alt">{t('nameVacationPlace')}</span></div>
                            <input required name="description_name" className="input input-bordered input-sm" type="text" value={vacationChange?.description_name??''} onChange={handleVacationChange} />
                        </label>
                        {vacation ? 
                        <h2 className='text-1xl md:text-1xl flex items-center'>
                            {(vacation.city)&&vacation.city+', '}
                            {i18nIsoCountries.getName(vacation.country, userData.lang)}
                        </h2>
                        :
                        <div className='text-1xl md:text-1xl flex flex-col gap-2 items-center'>
                            <label className="form-control w-full">
                                <div className="pl-1">
                                    <span className="label-text">{t("countryVacation")}</span>
                                </div>
                                <Select options={countriesList} defaultValue={countriesList.find((country:labelAndValue)=>country.value===vacationChange?.country)} onChange={handleCountryChange} placeholder='- -' theme={(theme:any)=>({...theme,borderRadius: "var(--rounded-btn, 0.5rem)"})} />
                                {/* <select required className="select select-bordered select-sm" name="country" onChange={handleVacationChange}>
                                    <option disabled selected value="">- -</option>
                                    {countriesList.map((country:any)=>( <option selected={country.value===vacationChange?.country} value={country.value}>{country.label}</option> ))}
                                </select> */}
                            </label>

                            {seeStatesList &&
                                <label className="form-control w-full">
                                    <div className="pl-1">
                                        <span className="label-text">{t("stateVacationPlace")}</span>
                                    </div>
                                    <Select options={statesList} defaultValue={statesList.find((state:labelAndValue)=>state.value===vacationChange?.state)} onChange={handleStateChange} isClearable={false} placeholder='- -' theme={(theme:any)=>({...theme,borderRadius: "var(--rounded-btn, 0.5rem)"})} />
                                    {/* <select className="select select-bordered select-sm" name="state" onChange={handleVacationChange}>
                                        <option disabled selected>- -</option>
                                        {statesList.map((state:any)=>( <option selected={state.value===vacationChange?.country} value={state.value}>{state.label}</option> ))}
                                    </select> */}
                                </label>
                            }
                            {seeCitiesList &&
                                <label className="form-control w-full">
                                    <div className="pl-1">
                                        <span className="label-text">{t("cityVacationPlace")}</span>
                                    </div>
                                    <Select options={citiesList} defaultValue={citiesList.find((city:labelAndValue)=>city.value===vacationChange?.city)} onChange={handleCityChange} isClearable={false} placeholder='- -' theme={(theme:any)=>({...theme,borderRadius: "var(--rounded-btn, 0.5rem)"})} />
                                    {/* <select className="select select-bordered select-sm" name="city" onChange={handleVacationChange}>
                                        <option disabled selected>- -</option>
                                        {citiesList.map((city:any)=>( <option selected={city.value===vacationChange?.country} value={city.value}>{city.label}</option> ))}
                                    </select> */}
                                </label>
                            }
                        </div>
                        }
                    </div>
                </div>
                <div className='flex flex-col w-full max-w-[1500px] gap-4'>
                    <div className='flex flex-col md:flex-row items-end justify-between gap-3 w-full bg-base-300 p-3 my-2'>
                        <span className='w-full mb-1'>📅 <b>{t("travelDates")}</b></span>
                        <span className='flex flex-col md:flex-row gap-1 w-full justify-end'>
                            <label className="form-control w-full">
                                <div className="pl-1"><span className="label-text-alt">{t('startVacation')}</span></div>
                                <input required name="date_start" className="input input-bordered input-sm" type="date" value={vacationChange?.date_start??today} min={today} onChange={handleVacationChange} />
                            </label>
                            <label className="form-control w-full">
                                <div className="pl-1"><span className="label-text-alt">{t('endVacation')}</span></div>
                                <input required name="date_end" className="input input-bordered input-sm" type="date" value={vacationChange?.date_end??today} min={vacationChange?.date_start??today} onChange={handleVacationChange} />
                            </label>
                        </span>
                    </div>
                    <div className='w-full p-4s flex flex-col lg:flex-row gap-4'>
                        <label className=" w-full lg:w-1/2 form-control">
                            <div className="pl-1">
                                <span className="label-text">{t("notes")}</span>
                            </div>
                            <textarea className="textarea textarea-bordered textarea-sm lg:h-64" placeholder={t("notes")} name="notes" value={vacationChange?.notes??''} onChange={handleVacationChange} ></textarea>
                        </label>
                        <div className='flex flex-col w-full lg:w-1/2 gap-3 lg:mt-8'>
                            {/* PAID */}
                            {getTier(userData?.tier) ? 
                                <div className='flex flex-col w-full gap-4s'>
                                    {(templatesTodos?.length)?<VacationFormTemplates name={t("todoListTemplate")} setTodos={handleTodoTemplateChange} templates={templatesTodos}/>:<div>✔️ {t('createTodoTemplate')}</div>}
                                    {(templatesPacking?.length)?<VacationFormTemplates name={t("packingListTemplate")} setTodos={handlePackingTemplateChange} templates={templatesPacking}/>:<div>🧳 {t('createPackingTemplate')}</div>}
                                </div>
                                :
                                <div className='flex items-center gap-3'>
                                    <h1 className="text-center">{t("templateUpgrade")}</h1>
                                    <Link to="/pricing" className='btn btn-sm btn-primary' >{t("upgradeNow")}</Link>
                                </div>
                            }
                            <div className="form-control">
                                <label className="label cursor-pointer">
                                    <span className="label-text">{t('useItinerary')}</span>
                                    <input type="checkbox" checked={itinerary} onChange={handleItineraryChange} className="checkbox checkbox-primary" />
                                </label>
                            </div>
                            <div className='flex w-full'>
                                <VacationLinks links={linksList} setLinks={setLinksList} />
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-around">
                        <button className="btn btn-primary" type="submit" >{(loading)?t("loading"):t("save")}</button>
                        <button className="btn btn-error " onClick={cancelEdit} >{t("cancel")}</button>
                    </div>
                </div>
            </form>
        </>
    ) : (
        <Loading />
    )
}