import React from 'react'
import Login from '../../../components/Login'
import { useTranslation } from 'react-i18next';

export default function Announce() {
    const { t } = useTranslation();
    return (
        <section className="bg-base-100 mt-20 mb-12 md:mb-24 md:mt-24 flex justify-center mx-8s" id="start">
            <div className="w-full flex flex-col items-center py-12 max-w-3xl rounded-xl bg-base-200 px-8">
                <h2 className="font-extrabold text-4xl tracking-tight mb-12 ">{t('howitWorks')}</h2>
                <div className='mb-12'>
                    <p>Sign up for free and create your personalised travel profile.</p>
                    <p>Plan your itinerary, create todo lists, and pack efficiently using our intuitive interface.</p>
                    <p>Access real-time weather updates and save important links directly in the app.</p>
                    <p>Add everything effortlessly with your calendar for a hassle-free vacation.</p>
                </div>
                <Login bigBtn hideLogout />
            </div>
        </section>
    )
}
