// import { today } from "./functions";

// let days=7
// const week = new Date(Date.now() + days*24*60*60*1000).getFullYear()+'-'+('0' + (new Date(Date.now() + days*24*60*60*1000).getMonth() + 1)).slice(-2)+'-'+('0' + new Date(Date.now() + days*24*60*60*1000).getDate()).slice(-2)

const exampleVacation = {
    city: 'London',
    commute: 'Airplane',
    country: 'GB',
    date_end: '2024-12-24',
    date_start: '2024-12-20',
    description_name: 'Example Vacation',
    id: 'examplevacation',
    notes: 'Notes about London:\n\nLondon, the capital of the United Kingdom, is a dynamic blend of tradition and innovation. Its iconic skyline is adorned with architectural wonders like the Shard and Tower Bridge, while historic landmarks like the Houses of Parliament and Big Ben remind us of its political heritage.\n\nThe city\'s multicultural essence is reflected in its diverse neighborhoods, world-class museums like the British Museum, and vibrant markets. London\'s green spaces like Hyde Park provide a breather from urban life, and its efficient public transport, including the iconic double-decker buses and the Tube, make navigating the city a breeze.\n\nAs a global financial hub, London\'s economic influence is profound, yet its rich history, showcased by sites like Buckingham Palace and the Tower of London, continues to shape its identity.',
    packing_list: [
        {
            id: '1',
            item: 'Casual outfits',
            checked: false,
        },
        {
            id: '2',
            item: 'Underwear and socks',
            checked: false,
        },
        {
            id: '3',
            item: 'Jacket or sweater',
            checked: false,
        },
        // {
        //     id: '4',
        //     item: 'Raincoat or travel umbrella',
        //     checked: false,
        // },
        {
            id: '5',
            item: 'Comfortable walking shoes',
            checked: false,
        },
        // {
        //     id: '6',
        //     item: 'Sunglasses',
        //     checked: false,
        // },
        {
            id: '7',
            item: 'Toothbrush and toothpaste',
            packed: false,
        },
        {
            id: '8',
            item: 'Shampoo and soap',
            packed: false,
        },
        // {
        //     id: '9',
        //     item: 'Body wash or soap',
        //     packed: false,
        // },
        {
            id: '10',
            item: 'Deodorant',
            packed: false,
        },
        {
            id: '11',
            item: 'Razor and shaving cream',
            packed: false,
        },
        // {
        //     id: '12',
        //     item: 'Prescription medications',
        //     packed: false,
        // },
        {
            id: '13',
            item: 'Phone and charger',
            packed: false,
        },
        // {
        //     id: '14',
        //     item: 'Laptop or tablet and charger',
        //     packed: false,
        // },
        {
            id: '15',
            item: 'Camera and accessories',
            packed: false,
        },
        {
            id: '16',
            item: 'Passport and visa',
            packed: false,
        },
        {
            id: '17',
            item: 'Travel insurance information',
            packed: false,
        },
        {
            id: '18',
            item: 'Flight tickets and itinerary',
            packed: false,
        },
        // {
        //     id: '19',
        //     item: 'Hotel reservations',
        //     packed: false,
        // },
        {
            id: '20',
            item: ' Money and credit/debit cards',
            packed: false,
        },
        {
            id: '21',
            item: 'Small backpack or daypack',
            packed: false,
        },
    ],
    state: '',
    todos: [
        {
            id: 'a1',
            text: 'Get Visas',
            checked: false,
        },
        {
            id: 'a2',
            text: 'Buy Travel Insurance',
            checked: false,
        },
        {
            id: 'a3',
            text: 'Search Chargers and Adapters',
            checked: false,
        },
        {
            id: 'a4',
            text: 'Notify Bank',
            checked: false,
        },
        {
            id: 'a5',
            text: 'Notify Contacts',
            checked: false,
        },
        {
            id: 'a6',
            text: 'Register for Vacation Vision',
            checked: false,
        },
    ],
    user_id: 'string',
    budget: 1200,
    image:[{
        url:'https://images.pexels.com/photos/460672/pexels-photo-460672.jpeg',
        owner:'Photo by Pixabay'
    }],
    budget_spendings:[
        {"id": "transport", "amount": "124.49", "description": "public transport"},
        {"id": "dinner", "amount": "57.83", "description": "dinner with friends"}
    ],
    saved_links: [
        {id:'link_1',description:'Hotel',link:'https://www.airbnb.de/'},
        {id:'link_2',description:'Flight',link:'https://www.skyscanner.com/'},
        {id:'link_3',description:'Visa',link:'https://en.wikipedia.org/wiki/Travel_visa'}
    ],
    itinerary:[{ "2024-12-20": "nothing planned yet" },{ "2024-12-21": "nothing planned yet" },{ "2024-12-22": "nothing planned yet" },{ "2024-12-23": "nothing planned yet" },{ "2024-12-24": "nothing planned yet" }
    ]
}

export default exampleVacation;
