import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import supabase from "../../../utils/supabase";
import VacationEditNew from "../../../components/VacationEditNew";
import LinkPath from "../../../components/LinkPath";
import { Layout, Loading } from "../../../components/GeneralComponents";

export default function NewVacation() {
    const [templatesTodos, setTemplatesTodos] = useState<Templates[]>()
    const [templatesPacking, setTemplatesPacking] = useState<Templates[]>()
    const [loaded, setLoaded] = useState(false);
    const navigate = useNavigate()

    
    useEffect(()=>{;
        const getTemplates = async () => {
            const { data:todos } = await supabase.from('templates').select('*').eq("del", false).eq("type", "todo")
            if (todos) { setTemplatesTodos(todos) }
            const { data:packing } = await supabase.from('templates').select('*').eq("del", false).eq("type","packing")
            if (packing) { setTemplatesPacking(packing) }
            setLoaded(true)
        }
        getTemplates()
    },[navigate])
    
    return loaded ? (
        <Layout>
            <LinkPath path={[{'name':'new','link':'new'}]}/>
            <VacationEditNew templatesTodos={templatesTodos} templatesPacking={templatesPacking}/>
        </Layout>
    ) : (
        <Loading />
    )
}