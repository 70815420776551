import { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
// @ts-ignore
import logo from "../img/logo.png";
import Login from "./Login";
import { useTranslation } from "react-i18next";
import { Menu, X } from "lucide-react";
import { UserContext } from "../App";



// A header with a logo on the left, links in the center (like Pricing, etc...), and a CTA (like Get Started or Login) on the right.
// The header is responsive, and on mobile, the links are hidden behind a burger button.
const Header = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [login,,userData] = useContext(UserContext);
    const links = [
        { href: "/home", label: t("dashboard"), },
        // { href: "/map", label: t("map"), },
        { href: "/settings", label: t("settings"), },
    ];
    // const linksPublic = [
    //     { href: "/blog", label: t("blog"), },
    // ];

    const handleLinkChange = (link:string) => {
        navigate(link);
        setIsOpen(false);
    }

    return (
        <nav className="bg-base-200 p-4">
            <div className="container mx-auto flex items-center justify-between">
                {/* Logo */}
                <div className="text-xl font-bold">
                    <Link className="flex flex-row items-center gap-2 shrink-0" to="/" title='hompage' >
                        <img src={logo} alt='vacation-vision-logo' className="w-8" width="32" height="32" />
                        <span className="font-extrabold text-lg ">Vacation Vision</span>
                    </Link>
                </div>
                {/* Burger Menu Button (hidden on larger screens) */}
                <div className="block md:hidden">
                    <button id="menu-btn" className=" focus:outline-none hover:text-[#958620]" onClick={() => setIsOpen(true)} ><Menu /></button>
                </div>
                {/* Links for larger screens */}
                <div className="hidden md:flex items-center space-x-6">
                    {/* {linksPublic.map((link) => (
                        <Link to={link.href} key={link.href} className="link link-hover" title={link.label} >{link.label}</Link>
                    ))} */}
                    {(login&&login!=='loading') && 
                        links.map((link) => (
                            <Link to={link.href} key={link.href} className="link link-hover" title={link.label} >{link.label}</Link>
                        ))
                    }
                    {userData?.id==='22262e0f-e634-4556-93b6-bc7b64793a3d'&&<Link to={'/blog'} key={'/blog'} className="link link-hover" title={'Blog'} >{'Blog'}</Link>}
                    <Login />
                </div>
                <div id="overlay-menu" className={(isOpen?"":"hidden")+" fixed inset-0 bg-neutral-200 flex flex-col items-center justify-center space-y-8 z-50"}>
                    {/* {linksPublic.map((link) => (
                        <Link to={link.href} key={link.href} className="link link-hover" title={link.label} ><button onClick={()=>handleLinkChange(link.href)} key={link.href} className="link link-hover" title={link.label} >{link.label}</button></Link>
                    ))} */}
                    {(login&&login!=='loading') && 
                        links.map((link) => (
                            <Link to={link.href} key={link.href} className="link link-hover" title={link.label} ><button onClick={()=>handleLinkChange(link.href)} key={link.href} className="link link-hover" title={link.label} >{link.label}</button></Link>
                        ))
                    }
                    {userData?.id==='22262e0f-e634-4556-93b6-bc7b64793a3d'&&<Link to={'/blog'} key={'/blog'} className="link link-hover" title={'Blog'} >{'Blog'}</Link>}
                    <Login />
                    {/* Close button always visible when overlay is active */}
                    <button id="close-btn" className="absolute top-4 right-4 focus:outline-none hover:text-[#958620]" onClick={() => setIsOpen(false)} ><X /></button>
                </div>

            </div>
        </nav>
    );
};

export default Header;
