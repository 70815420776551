// supabase functions download <Function name> [flags]
import { Navigate, Route, Routes } from "react-router-dom";
import { useEffect, createContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {getUser} from './utils/functions';
import i18nIsoCountries from 'i18n-iso-countries';
import './utils/i18n';
import supabase from "./utils/supabase";
import Header from "./components/Header";
import LandingPage from "./Pages/(public)/LandingPage/LandingPage";
import Imprint from "./Pages/(public)/imprint/Imprint";
import TOS from "./Pages/(public)/tos/tos";
import Privacy from "./Pages/(public)/privacy/Privacy";
import Dashboard from './Pages/(privat)/Dashboard';
import DestinationsDetails from "./Pages/(privat)/(destination)/DestinationsDetails";
import NewVacation from "./Pages/(privat)/(new)/NewVacation";
import DestinationsEdit from "./Pages/(privat)/(destination)/DestinationsEdit";
import Settings from "./Pages/(privat)/(settings)/Settings";
import Feedback from "./Pages/(privat)/(feedback)/Feedback";
import { Loading } from "./components/GeneralComponents";
import Cookies from "./components/Cookies";
import BlogMain from "./Pages/(public)/(blog)/BlogMain";
import BlogPost from "./Pages/(public)/(blog)/BlogPost";

i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/en.json"));
i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/de.json"));
// i18nIsoCountries.registerLocale(require("i18n-iso-countries/langs/es.json"));

export const UserContext = createContext<Users|undefined>(undefined)

function App() {
    const { i18n } = useTranslation();
    const [userData, setUserData] = useState<Users|undefined>(undefined)
    const [login, setLogin] = useState<string>('loading');

    useEffect(() => {
        if (userData) { i18n.changeLanguage(userData.lang) }
        else { i18n.changeLanguage(navigator.language) }
    }, [i18n,userData])

    useEffect(()=>{
        const getUserData=async () =>{
            const user = await getUser()
            if (user) {
                setUserData(user);
            }
        }
        supabase.auth.onAuthStateChange((event, session) => {
            if (session) {
                getUserData()
            }
        })
    },[])

    return (
        <UserContext.Provider value={[login, setLogin, userData]}>
            <Header />
            {(login==='loading')?
            <Loading/>
            :
            <div className="">
                <Routes>
                    {/* <Route path='/' element={<LandingPage loggedin={true} />} /> */}
                    {(login) && 
                    <>
                    <Route path='/home' element={<Dashboard />} />
                    <Route path='/home/:id' element={<DestinationsDetails />} />
                    <Route path='/home/:id/edit' element={<DestinationsEdit />} />
                    <Route path='/new' element={<NewVacation />} />
                    <Route path='/settings' element={<Settings />} />
                    <Route path='/feedback' element={<Feedback />} />
                    </>
                    }
                    <Route path='/' element={<LandingPage />} />
                    {/* <Route path='/blog' element={<BlogMain />} /> */}
                    {userData?.id==='22262e0f-e634-4556-93b6-bc7b64793a3d'&&<Route path='/blog' element={<BlogMain />} />}
                    {userData?.id==='22262e0f-e634-4556-93b6-bc7b64793a3d'&&<Route path='/blog/:slug' element={<BlogPost />} />}
                    <Route path='/imprint' element={<Imprint />} />
                    <Route path='/tos' element={<TOS />} />
                    <Route path='/privacy-policy' element={<Privacy />} />
                    <Route path="*" element={<Navigate to="/" />} />
                </Routes>
            </div>
            }
            <Cookies />
        </UserContext.Provider>
    )
}

export default App;
