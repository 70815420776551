import React, { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';
import { Layout } from '../../../components/GeneralComponents';
import { Helmet } from 'react-helmet-async';
import { formatBlogTitle } from '../../../utils/functions';
import ImageWithFallback from '../../../components/ImageWithFallback';

// @ts-ignore
const markdownFiles = require.context('../../../blogposts', false, /\.md$/);

export default function BlogPost() {
    const { slug } = useParams<{ slug: string }>();
    const [markdownContent, setMarkdownContent] = useState<string>('');

    useEffect(() => {
        const postFile = markdownFiles(`./${slug}.md`); // Dynamically import based on slug
        fetch(postFile)
            .then((response) => response.text())
            .then((text) => setMarkdownContent(text));
    }, [slug]);

    return (
        <Layout>
            <Helmet>
                <title>Blog | {formatBlogTitle(slug!)}</title>
            </Helmet>
            <div className={'relative flex justify-center w-full h-96 my-2 groups rounded-lg smb-28 mb-12'}>
                <div className='absolute top-0 left-0 min-w-full min-h-full w-full h-full object-cover brightness-50 '>
                    <ImageWithFallback src={slug!}/>
                </div>
                <div className='absolute w-full h-full flex flex-col md:flex-row items-center justify-center p-3'>
                    <h1 className='text-3xl md:text-5xl text-white'>{formatBlogTitle(slug!)}</h1>
                </div>
            </div>
            <ReactMarkdown components={{ h1: ({ node, children, ...props }) => <h1 className="text-3xl font-bold mb-4" {...props} >{children}</h1>, h2: ({ node, children, ...props }) => <h2 className="text-2xl font-bold mb-3" {...props} >{children}</h2>, p: ({ node, children, ...props }) => <p className="mb-4 text-gray-700" {...props} >{children}</p>, ul: ({ node, ...props }) => <ul className="list-disc list-inside mb-4" {...props} />, li: ({ node, ...props }) => <li className="mb-2" {...props} />, a: ({ node, children, ...props }) => <a className="text-primary underline" {...props} >{children}</a>, img: ({ src, alt, ...props }) => { try { return <div className='w-full flex justify-center'><img src={require("../../../blogposts/images/"+src?.split('/').pop()).default || require("../../../blogposts/images/"+src?.split('/').pop())} alt={alt} className="max-w-xl w-full rounded-lg" {...props} /></div>; } catch (e) {return null;}} }} >
                {markdownContent}
            </ReactMarkdown>
        </Layout>
    )
}
