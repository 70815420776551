import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import config from '../../../config';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

// CHATGPT PROMPT TO GENERATE YOUR TERMS & SERVICES — replace with your own data 👇

// 1. Go to https://chat.openai.com/
// 2. Copy paste bellow
// 3. Replace the data with your own (if needed)
// 4. Paste the answer from ChatGPT directly in the <pre> tag below

// You are an excellent lawyer.

// I need your help to write a simple Terms & Services for my website. Here is some context:
// - Website: 
// - Name: 
// - Contact information: 
// - Description: An application responsible for help Personal Trainers and People that like to go to the gym improve their performance.
// - Ownership: when buying a plan/subscription, users can interact with many features. They can ask for a full refund within 7 day after the purchase.
// - User data collected: name, email, phone and payment information
// - Non-personal data collection: web cookies
// - Link to privacy-policy: /privacy-policy
// - Governing Law: 
// - Updates to the Terms: users will be updated by email

// Please write a simple Terms & Services for my site. Add the current date. Do not add or explain your reasoning. Answer:

export default function TOS() {
    const { t } = useTranslation();
    useEffect(() => {
        document.title = `${t('tos')} | ${config.appName}`;
    }, [t]);
    return (
        <main className="max-w-xl mx-auto">
            <div className="p-5">
                <Link to="/" className="btn btn-ghost">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5"><path fillRule="evenodd" d="M15 10a.75.75 0 01-.75.75H7.612l2.158 1.96a.75.75 0 11-1.04 1.08l-3.5-3.25a.75.75 0 010-1.08l3.5-3.25a.75.75 0 111.04 1.08L7.612 9.25h6.638A.75.75 0 0115 10z" clipRule="evenodd"/></svg>
                    {t('back')}
                </Link>
                <h1 className="text-3xl font-extrabold pb-6">{t('tos')} {config.appName}</h1>
                {i18n.language==='de'?
                <pre className="leading-relaxed whitespace-pre-wrap" style={{ fontFamily: "Nunito" }} >
                    <p className="text-sm text-gray-500 mb-4">Zuletzt aktualisiert: 10. Oktober 2024</p>
                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">1. Einführung</h2>
                        <p>Willkommen bei Vacation Vision. Diese Datenschutzerklärung beschreibt, wie wir Deine Informationen erfassen, verwenden und schützen, wenn Du unsere Website vacation-vision.com nutzt.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">2. Erhobene Daten</h2>
                        <p className="font-semibold">Persönliche Daten:</p>
                        <ul className="list-disc list-inside ml-4">
                            <li>Name</li>
                            <li>E-Mail</li>
                            <li>Telefon</li>
                            <li>Zahlungsinformationen</li>
                            <li>Adresse</li>
                        </ul>
                        <p className="font-semibold mt-4">Nicht-personenbezogene Daten:</p>
                        <p>Wir erfassen Web-Cookies, um Deine Erfahrung auf unserer Website zu verbessern.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">3. Zweck der Datenerhebung</h2>
                        <p>Wir erheben Deine Daten, um unsere Kunden besser zu verstehen und zu bedienen.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">4. Datenweitergabe</h2>
                        <p>Wir geben Deine Daten nicht an Dritte weiter.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">5. Datenschutz von Kindern</h2>
                        <p>Wir erheben keine Daten von Kindern.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">6. Änderungen an dieser Datenschutzerklärung</h2>
                        <p>Sollten sich Änderungen an dieser Datenschutzerklärung ergeben, werden die Nutzer per E-Mail informiert.</p>
                    </section>

                    <section>
                        <h2 className="text-2xl font-semibold mb-2">7. Kontakt</h2>
                        <p>Falls Du Fragen oder Bedenken hast, kontaktiere uns bitte unter <a href="mailto:contact@vacation-vision.com" className="text-blue-500 underline">contact@vacation-vision.com</a>.</p>
                    </section>
                    <footer className="mt-8 text-center text-gray-500">
                        <p>Vielen Dank, dass Du Vacation Vision nutzen.</p>
                        <p>Das Vacation Vision Team</p>
                    </footer>
                </pre>
                :
                <pre className="leading-relaxed whitespace-pre-wrap" style={{ fontFamily: "Nunito" }} >
                    <p className="text-sm text-gray-500 mb-4">Last updated: October 10, 2024</p>
                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">1. Introduction</h2>
                        <p>Welcome to Vacation Vision. This privacy policy describes how we collect, use, and protect your information when you use our website vacation-vision.com.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">2. Collected Data</h2>
                        <p className="font-semibold">Personal Data:</p>
                        <ul className="list-disc list-inside ml-4">
                            <li>Name</li>
                            <li>Email</li>
                            <li>Phone</li>
                            <li>Payment Information</li>
                            <li>Address</li>
                        </ul>
                        <p className="font-semibold mt-4">Non-personal Data:</p>
                        <p>We collect web cookies to improve your experience on our website.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">3. Purpose of Data Collection</h2>
                        <p>We collect your data to better understand and serve our customers.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">4. Data Sharing</h2>
                        <p>We do not share your data with third parties.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">5. Children’s Privacy</h2>
                        <p>We do not collect data from children.</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">6. Changes to this Privacy Policy</h2>
                        <p>If any changes are made to this privacy policy, users will be notified via email.</p>
                    </section>

                    <section>
                        <h2 className="text-2xl font-semibold mb-2">7. Contact</h2>
                        <p>If you have any questions or concerns, please contact us at <a href="mailto:contact@vacation-vision.com" className="text-blue-500 underline">contact@vacation-vision.com</a>.</p>
                    </section>
                    <footer className="mt-8 text-center text-gray-500">
                        <p>Thank you for using Vacation Vision.</p>
                        <p>The Vacation Vision Team</p>
                    </footer>
                </pre>
                }
            </div>
        </main>
    );
}