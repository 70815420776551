import { useContext, useEffect, useState } from 'react'
import { sendError } from '../utils/functions';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import { vacationContext } from '../Pages/(privat)/(destination)/DestinationsDetails';
import { Circle, CircleCheck, Eye, EyeOff, Pencil, Plus, Save, Trash } from 'lucide-react';
import { PackingCard } from './PackingCard';
import supabase from '../utils/supabase';

export const PackingContainer = ({list,vacationId,number}:{list:Array<PackingItem>,vacationId:string,number:number}) => {
    const [dataVacation, setDataVacation] = useContext(vacationContext);
    const { t } = useTranslation();
    const [listItems, setListItems] = useState<PackingItem[]>((list)?list:[]);
    const [editingItemId, setEditingItemId] = useState<string>('');
    const [showOldPacking, setShowOldPacking] = useState(false);
    const [allDone, setAllDone] = useState(false);

    useEffect(()=>{
        if (listItems.length) {
            const unpacked = listItems.find(item=>item.packed===false);
            if (!unpacked) setAllDone(true)
            else setAllDone(false)
            
        }
    },[listItems])

    const toggleShowPacking = () => { setShowOldPacking(!showOldPacking) }

    const handleItemChange = (id: string, newText: string) => {
        setListItems((prevItems:PackingItem[]) =>
            prevItems.map(input =>
                input.id === id ? { ...input, item: newText } : input
            )
        );
    };

    const handleAmountChange = (id: string, newAmountString: string) => {
        const newAmount = parseInt(newAmountString);
        setListItems((prevItems:PackingItem[]) =>
            prevItems.map(input =>
                input.id === id ? { ...input, amount: newAmount } : input
            )
        );
    };

    const handleNew = (save:boolean) => {
        if (save) { 
            if (listItems[listItems.length - 1].item==='') {
                setEditingItemId('');
                setListItems(listItems.filter(input => input.item !== ''))
                return false
            }
            else {
                saveInDB(listItems);
            }
        }

        
        const newId = v4()
        setListItems((prevItems:PackingItem[]) => [...prevItems, { id: newId, item: '', packed: false, amount: 1 }]);
        handleEdit(newId);
    };
    const handleDelete = (id: string) => {
        setListItems((prevItems:PackingItem[]) => prevItems.filter(input => input.id !== id));
        const updatedList = listItems.filter(input => input.id !== id);
        saveInDB(updatedList)
    };
    const handleEdit = (itemId:string) => {
        setEditingItemId(itemId);
    };
    const handleSave = (itemId:string) => {
        setEditingItemId('');
        setListItems(listItems.filter(input => input.item !== ''))
        saveInDB(listItems)
    };

    const handleCheckboxChange = (id:string) => {
        setListItems((prevItems:PackingItem[]) =>
            prevItems.map(input =>
                input.id === id ? { ...input, packed: !input.packed } : input
            )
        );
        const updatedList = listItems.map(input =>
            input.id === id ? { ...input, packed: !input.packed } : input
        );
        saveInDB(updatedList)
    };

    const saveInDB = async (newList:PackingItem[]) => {
        if (vacationId!=='examplevacation') {
            const {data, error} = await supabase.from('vacations').update({ 
                // packing_list:newList,
                [number === 1 ? "packing_list" : `packing_list${number}`]:newList,
            })
            .eq('id', vacationId)
            .select()
            .single()
            if (dataVacation) {}
            if (data) { setDataVacation(data); }
            else { sendError(error); }
            
        }
    }

    const moveItem = (dragIndex: number, hoverIndex: number) => {
        const draggedItem = listItems[dragIndex];
        const updatedItems = [...listItems];
        updatedItems.splice(dragIndex, 1);
        updatedItems.splice(hoverIndex, 0, draggedItem);
        setListItems(updatedItems);
    };

    const saveSortOrder = () => {
        saveInDB(listItems)
    };

    return (
        <div className='flex flex-col items-centers w-full'>
            <div className='flex'>
                <div className='w-full flex justify-end mb-2'>
                {showOldPacking ? <button className='flex items-center gap-1' onClick={toggleShowPacking}>{t("hide")}<EyeOff size="22" /></button> : <button className='flex items-center gap-1' onClick={toggleShowPacking}>{t("show")}<Eye size="22" /></button> }
                </div>
            </div>
            <div className='flex flex-col justify-center items-center flex-wrap w-full'>
                {listItems?.map((item,i)=> (
                    (!item.packed||showOldPacking) &&
                    <PackingCard key={item.id} index={i} id={item.id} moveCard={moveItem} saveSortOrder={saveSortOrder} >
                        <div className={"relative flex justify-between items-center my-3 lg:my-2 p-2 lg:p-0 w-full max-w-2xl border border-transparent self-center bg-base-300 text-lg rounded-lg"} key={item.id}>
                            <input className="w-1/12" hidden type="checkbox" name="check" id={item.id} checked={item.packed} onChange={() => handleCheckboxChange(item.id)}/>
                            {editingItemId === item.id ? (
                                <div className='flex items-center gap-2 w-full 11/12'>
                                    <button className='p-2 text-red-500 hover:text-red-600 transition' onClick={() => handleDelete(item.id)}><Trash size="20" /></button>
                                    <input className="input input-bordered input-sm w-9/12" autoFocus onFocus={e => e.currentTarget.select()} value={item.item} onChange={(e) => handleItemChange(item.id,e.target.value)} onKeyDown={(event) => { if (event.key === 'Enter') handleNew(true)}} />
                                    <input className="input input-bordered input-sm w-3/12" type="number" min={1} value={item.amount} onChange={(e) => handleAmountChange(item.id,e.target.value)} onKeyDown={(event) => { if (event.key === 'Enter') handleNew(true)}} />
                                    <button className='ml-3 p-2' onClick={() => handleSave(item.id)}><Save size="20" /></button>
                                </div>
                            ):(
                                <div className={'flex items-center w-full 11/12 transition-all '+((item.packed)?' text-slate-400 line-through':'')}>
                                    {item.packed && <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 w-11/12 h-px bg-slate-400 "></div>}
                                    <label htmlFor={item.id} className='flex items-center p-2 hover:cursor-pointer'>{item.packed?<CircleCheck className='' size={20}/>:<Circle size={20}/>}</label>
                                    <p className='w-full lg:text-sm'>{item.item}</p>
                                    <p className='lg:text-sm'>{item.amount}</p>
                                    <button className='ml-3 p-2' onClick={() => handleEdit(item.id)}><Pencil size="20" /></button>
                                </div>
                            )}
                        </div>
                    </PackingCard>
                ))}
                {(allDone&&!showOldPacking) && <div className='my-5 text-lg'>{t('allPacked')}</div> }
            </div>
            <div className="w-full flex">
                <button className="btn btn-sm btn-circle btn-primary" type="button" onClick={()=>handleNew(false)}><Plus /></button>
            </div>
        </div>
    )
}