import React from 'react'

export default function ImageWithFallback({src,header}:{src:string,header?:boolean}) {
    try {
        // Try to require the image dynamically
        const imagePath = require("../blogposts/images/"+src.split('__')[1]+".png");
        return <img className="w-full h-full object-cover rounded-xl" src={imagePath} alt={src} />
    }
    catch (e) {
        const imagePathDefault = require("../blogposts/images/default.png");
        return <img className="w-full h-full object-cover rounded-xl" src={imagePathDefault} alt={src} />
    }
}
