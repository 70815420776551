import { Country, State } from "country-state-city";
import supabase from "./supabase";
import { sendTelegramError } from "./telegram";
import fetchImages from "./pexels";

export const today = new Date().getFullYear()+'-'+('0' + (new Date().getMonth() + 1)).slice(-2)+'-'+('0' + new Date().getDate()).slice(-2)

export const getMoreAllowed = async () => {
    const { data:vacations } = await supabase.from('vacations').select('id').eq('canceled',false);
    if (vacations) {
        if (vacations.length>=5) return false;
        else return true;
    }
}

export const getUser = async () => {
    const { data:user } = await supabase.from('users').select('*').single()
    if (user) { 
        if (user.tier!=='free') { user.moreAllowed=true; }
        else { user.moreAllowed=await getMoreAllowed(); }
        return user
    }
    else { return false }
}

export const sendError = async (error:any) => {
    const cleanError=JSON.stringify(error,null,2)
    sendTelegramError(cleanError);
}

export const getTier = (tier:string) => {
    // return 0;
    if (tier==="lifetime") return 10;
    else if (tier==="paid") return 1;
    else return 0;
}

export const updateItinerary = (itinerary:Array<DateObject>,date1:string,date2:string,empty:string) => {
    let newItinerary;
    if (itinerary) {
        let currentItinerary = itinerary.map((dates:DateObject) => { return Object.keys(dates)[0] })
        const dateArray = getDatesInBetween(date1,date2)
        newItinerary = dateArray.map(date=>{ 
            const dateExistsItinerrary = currentItinerary.find((element) => {return element === date});
            if (dateExistsItinerrary) {
                const prevDateValue = itinerary.find((element) => { 
                    if (Object.keys(element)[0] === dateExistsItinerrary) { return element[Object.keys(element)[0]]; }
                    else { return null; }
                });
                if (prevDateValue) { return {[date]:prevDateValue[Object.keys(prevDateValue)[0]]} }
            }
            return {[date]:empty}
        })
    }
    else {
        const dateArray = getDatesInBetween(date1,date2)
        newItinerary = dateArray.map(date=>{ return {[date]:empty} })
    }
    return newItinerary;
}

export const getDatesInBetween = (date1:string, date2:string) => {
    // Convert strings to Date objects
    const startDate = new Date(date1);
    const endDate = new Date(date2);
    // Initialize an array to store the result
    const dateArray = [];
    // Loop through each day and push the formatted date into the array
    let currentDate = startDate;
    while (currentDate <= endDate) {
        dateArray.push(currentDate.toISOString().split('T')[0]);
        currentDate.setDate(currentDate.getDate() + 1);
    }
    return dateArray;
}

export const getDaysBefore = (x:number) => {
    const daysBeforeTmp = new Date();
    daysBeforeTmp.setDate(daysBeforeTmp.getDate() - x);
    const daysBefore: any = daysBeforeTmp.getFullYear()+'-'+(daysBeforeTmp.getMonth() + 1).toString().padStart(2, '0')+'-'+daysBeforeTmp.getDate().toString().padStart(2, '0');
    return daysBefore;
}

export const getDaysAfter = (x:number) => {
    const daysBeforeTmp = new Date();
    daysBeforeTmp.setDate(daysBeforeTmp.getDate() + x);
    const daysBefore: any = daysBeforeTmp.getFullYear()+'-'+(daysBeforeTmp.getMonth() + 1).toString().padStart(2, '0')+'-'+daysBeforeTmp.getDate().toString().padStart(2, '0');
    return daysBefore;
}

// export const sendNotify = (msg:string,error?:boolean) => {
//     const options:any= {
//         position: "top-left",
//         autoClose: 500,
//         hideProgressBar: true,
//         closeOnClick: true,
//         pauseOnHover: false,
//         draggable: true,
//         progress: undefined,
//         theme: "dark",
//     };
//     if (error) toast.error(msg, options);
//     else toast.success(msg, options);
// }

export const getVisaData = async (origin:string,destination:string) => {
    // https://github.com/nickypangers/passport-visa-api/tree/master?tab=readme-ov-file
    const url='https://visa-check-jonas1220.vercel.app/'+origin+'/'+destination;
    try {
        const res = await fetch(url)
        const json = await res.json()
        return json;
    } catch (error) {
        return {error:{status:true}}
    }
}

export const fetchPhotoVacation = async (vacation:Vacations,force?:boolean) => {
    const country = Country.getCountryByCode(vacation.country)
    const state = State.getStateByCodeAndCountry(vacation.state,vacation.country)
    const city = vacation.city
    let randomImage;
    if (vacation.image&&!force) { return vacation.image[0]; }
    if (city) {
        let photo = await fetchImages(city)
        if (!photo.length) {console.log('2');photo = await fetchImages(vacation.description_name)}
        if (!photo.length&&country) {console.log('3');photo = await fetchImages(country.name)}
        if (photo.length) {
            randomImage=Math.floor(Math.random() * photo.length);
            savePhotoVacation(vacation,photo[randomImage].url,photo[randomImage].owner,photo[randomImage].owner_url);
            const ret = {
                url:photo[randomImage].url,
                owner:photo[randomImage].owner,
                owner_url:photo[randomImage].owner_url
            }
            return ret;
        }
    }
    else if (state) {
        let photo = await fetchImages(state.name)
        if (!photo.length&&country) {photo = await fetchImages(country.name)}
        if (photo.length) {
            randomImage=Math.floor(Math.random() * photo.length);
            savePhotoVacation(vacation,photo[randomImage].url,photo[randomImage].owner,photo[randomImage].owner_url);
            const ret = {
                url:photo[randomImage].url,
                owner:photo[randomImage].owner,
                owner_url:photo[randomImage].owner_url
            }
            return ret;
        }
    }
    else if (country) {
        const photo = await fetchImages(country.name)
        if (photo.length) {
            randomImage=Math.floor(Math.random() * photo.length);
            savePhotoVacation(vacation,photo[randomImage].url,photo[randomImage].owner,photo[randomImage].owner_url);
            const ret = {
                url:photo[randomImage].url,
                owner:photo[randomImage].owner,
                owner_url:photo[randomImage].owner_url
            }
            return ret;
        }
    }
}

export const savePhotoVacation = async (vacation:Vacations,url_new:string,owner_new:string,owner_url_new:string) => {
    // TODO: save photo data
    const { data } = await supabase.from('vacations').update({ 
        image:[{
            url: url_new,
            owner: owner_new,
            owner_url: owner_url_new
        }]
    })
    .eq('id', vacation.id)
    .select()
    if (!data) {
        fetchPhotoVacation(vacation)
    }
}

export const getCurrencyConversion = async (orign:string, destination:string) => {
    if (orign===destination) return false;
    else {
        const apiKey = 'd8b81c4a7a9cb064cacb2413';
        const response = await fetch('https://v6.exchangerate-api.com/v6/'+apiKey+'/latest/'+orign)
        const json = await response.json();
        const conversion=json.conversion_rates[destination];
        return conversion.toFixed(2);
    }
}

export const checkIfJonas = (userID:string) => {
    if (userID==='22262e0f-e634-4556-93b6-bc7b64793a3d') return true;
    else return false;
}

export const formatBlogTitle = (str:string) => {
    str=str.split('__')[1]
    return str.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}