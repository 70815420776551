import React, { useEffect } from 'react'
import config from "../../../config";
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

export default function Imprint() {
    const { t } = useTranslation();
    useEffect(() => {
        document.title = `${t('imprint')} | ${config.appName}`;
    }, [t]);
    return (
        <main className="max-w-xl mx-auto">
            <div className="p-5">
                <Link to="/" className="btn btn-ghost">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5" ><path fillRule="evenodd" d="M15 10a.75.75 0 01-.75.75H7.612l2.158 1.96a.75.75 0 11-1.04 1.08l-3.5-3.25a.75.75 0 010-1.08l3.5-3.25a.75.75 0 111.04 1.08L7.612 9.25h6.638A.75.75 0 0115 10z" clipRule="evenodd" /></svg>{" "}
                    {t('back')}
                </Link>
                <h1 className="text-3xl font-extrabold pb-6">{t('imprint')} {config.appName}</h1>
                {i18n.language==='de'?
                <pre className="leading-relaxed whitespace-pre-wrap" style={{ fontFamily: "Nunito" }} >
                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">Kontakt</h2>
                        <p>Jonas Kaatz<br/>Mühlstraße 30<br/>65375 Oestrich-Winkel<br/>Deutschland<br/><a className='text-blue-500 underline' href="mailto:contact@vacation-vision.com">contact@vacation-vision.com</a></p>
                    </section>
                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">Umsatzsteuer-ID</h2>
                        <p>Umsatzsteuer-Identifikationsnummer gemäß §27a Umsatzsteuergesetz:<br/>DE337014471</p>
                    </section>
                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">Bildrechte</h2>
                        <p><a className='text-blue-500 underline' href="mailto:https://www.pexels.com/">https://www.pexels.com/</a></p>
                    </section>
                    <footer className="mt-8 text-center text-gray-500">
                        <p>Vielen Dank, dass Du Vacation Vision nutzen.</p>
                        <p>Das Vacation Vision Team</p>
                    </footer>
                </pre>
                :
                <pre className="leading-relaxed whitespace-pre-wrap" style={{ fontFamily: "Nunito" }} >
                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">Contact</h2>
                        <p>Jonas Kaatz<br/>Mühlstraße 30<br/>65375 Oestrich-Winkel<br/>Germany<br/><a className='text-blue-500 underline' href="mailto:contact@vacation-vision.com">contact@vacation-vision.com</a></p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">VAT ID</h2>
                        <p>Value Added Tax Identification Number according to §27a of the German Value Added Tax Act:<br/>DE337014471</p>
                    </section>

                    <section className="mb-6">
                        <h2 className="text-2xl font-semibold mb-2">Image Rights</h2>
                        <p><a className='text-blue-500 underline' href="mailto:https://www.pexels.com/">https://www.pexels.com/</a></p>
                    </section>

                    <footer className="mt-8 text-center text-gray-500">
                        <p>Thank you for using Vacation Vision.</p>
                        <p>The Vacation Vision Team</p>
                    </footer>
                </pre>
                }
            </div>
        </main>
    )
}