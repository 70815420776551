import React, { createContext, useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import LinkPath from '../../../components/LinkPath'
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../App';
import { fetchPhotoVacation, getDatesInBetween, sendError, today, updateItinerary } from '../../../utils/functions';
import supabase from '../../../utils/supabase';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend'
import { isBrowser } from 'react-device-detect';
import i18nIsoCountries from 'i18n-iso-countries';
import { CalendarClock, DollarSign, Home, List, LogIn, Luggage, Pencil, RefreshCw, Save, Undo } from 'lucide-react';
import { Country } from 'country-state-city';
import ProgressBar from '../../../components/ProgressBar';
import Weather from '../../../components/weather';
import Modal from '../../../components/Modal';
import { PackingContainer } from '../../../components/PackingContainer';
import DateContainer from '../../../components/DateContainer';
// import VisaToolTip from '../../../components/VisaTooltip';
import exampleVacation from '../../../utils/example';
import Budget from '../../../components/Budget';
import { TodoContainer } from '../../../components/TodoContainer';
import { Layout, Loading } from '../../../components/GeneralComponents';
import { Helmet } from 'react-helmet-async';

const opt = {
    delayTouchStart: 250
}

export const vacationContext = createContext<any>(false)

export default function DestinationsDetails() {
    const { t } = useTranslation();
    const [,,userData] = useContext(UserContext);
    const {id:vacationId} = useParams()
    const [loaded, setLoaded] = useState(false);
    const [past, setPast] = useState(false);
    const [dataVacation, setDataVacation] = useState<any>()
    const [photoUrl, setPhotoUrl] = useState('')
    const [alignment, setAlignment] = useState<string>('overview');
    const navigate = useNavigate()


    useEffect(()=>{;
        const getData = async () => {
            const {data: vacationTmp, error} = await supabase.from('vacations').select('*, users(tier)').eq('id', vacationId).single();
            if (!error) { 
                const fetchPhoto = await fetchPhotoVacation(vacationTmp);
                setPhotoUrl(fetchPhoto?.url)
                setDataVacation(vacationTmp)
                if (vacationTmp.canceled||vacationTmp.date_end<today) setPast(true)
            }
            setLoaded(true);
        }
        if (vacationId==='examplevacation') { 
            setDataVacation(exampleVacation)
            setPhotoUrl(exampleVacation.image[0].url)
            setLoaded(true);
        }
        else {
            getData()
        }
    },[navigate,vacationId])

    const handleAlignment = (newAlignment: string) => {
        if (newAlignment) setAlignment(newAlignment);
    };
    const getNewImage = async () => {
        const fetchPhoto = await fetchPhotoVacation(dataVacation,true);
        setPhotoUrl(fetchPhoto.url)
    }
    return loaded ? (
        <Layout>
            <Helmet>
                <title>{Country.getCountryByCode(dataVacation.country)?.flag+' '+dataVacation.description_name+' | Vacation Vision'}</title>
            </Helmet>
            <vacationContext.Provider value={[dataVacation, setDataVacation]}>
                <div className='flex justify-between items-center'>
                    <LinkPath path={[{'name':dataVacation.description_name,'link':'home/'+dataVacation.id}]}/>
                    {(dataVacation.id==='examplevacation')?
                        <Link to={'/login'} className='text-xl md:text-3xl'><LogIn /></Link>
                    :
                        <Link to={'/home/'+dataVacation.id+'/edit'} className='text-xl md:text-3xl'><Pencil /></Link>
                    }
                </div>
                <div className={'relative flex justify-center w-full h-32 md:h-52 my-2 groups rounded-lg mb-28 md:mb-12'}>
                    <div className='absolute top-0 left-0 min-w-full min-h-full w-full h-full object-cover brightness-50 '>
                        {(photoUrl)?
                            <img className="w-full h-full object-cover rounded-lg group-hover:blur-sms transition duration-200" src={photoUrl} alt="" />
                        :
                            <div className='w-full h-full rounded-lg group-hover:blur-sms transition duration-200 bg-slate-700'></div>
                        }
                    </div>
                    <div className='absolute w-full h-full flex flex-col md:flex-row items-center justify-between p-3'>
                        <div className='flex flex-col justify-between h-full w-full'>
                            <h1 className='text-3xl md:text-5xl text-white'>{dataVacation.description_name} {Country.getCountryByCode(dataVacation.country)?.flag}</h1>
                            <h2 className='text-1xl md:text-1xl flex items-center text-white'>
                                {(dataVacation.city)&&dataVacation.city+', '}
                                {userData ? i18nIsoCountries.getName(dataVacation.country, userData.lang) : i18nIsoCountries.getName(dataVacation.country, 'en') }
                                {/* {(userData&&dataVacation.country!==userData.passport) && <VisaToolTip destination={dataVacation.country} /> } */}
                            </h2>
                        </div>
                        <button className='btn btn-sm btn-circle absolute top-2 right-2' onClick={getNewImage}><RefreshCw size={16} /></button>
                        <DateContainer dataVacation={dataVacation} />
                    </div>
                </div>
                <div role="tablist" className="tabs tabs-boxed lg:hidden">
                    <button role="tab" className={"tab flex gap-2 hover:bg-neutral-content"+(alignment==='overview'?' tab-active':'')} onClick={()=>handleAlignment('overview')} ><Home /><span className='hidden md:flex'>{t('overview')}</span></button>
                    <button role="tab" className={"tab flex gap-2 hover:bg-neutral-content"+(alignment==='packing'?' tab-active':'')} onClick={()=>handleAlignment('packing')} ><Luggage /><span className='hidden md:flex'>{t('packingList')}</span></button>
                    <button role="tab" className={"tab flex gap-2 hover:bg-neutral-content"+(alignment==='todo'?' tab-active':'')} onClick={()=>handleAlignment('todo')} ><List /><span className='hidden md:flex'>{t('todos')}</span></button>
                    {dataVacation.itinerary[0]!==false &&
                    <button role="tab" className={"tab flex gap-2 hover:bg-neutral-content"+(alignment==='itinerary'?' tab-active':'')} onClick={()=>handleAlignment('itinerary')} ><CalendarClock /><span className='hidden md:flex'>{t('itinerary')}</span></button>
                    }
                    <button role="tab" className={"tab flex gap-2 hover:bg-neutral-content"+(alignment==='budget'?' tab-active':'')} onClick={()=>handleAlignment('budget')} ><DollarSign /><span className='hidden md:flex'>{t('budget')}</span></button>
                </div>
                <div className='flex flex-col lg:flex-row gap-s2'>
                    <div className="flex lg:flex-col w-full lg:w-1/2">
                        <div className={(alignment!=='overview'?'hidden':'')+' w-full lg:flex p-2 lg:p-3'}>
                            <OverviewSection dataVacation={dataVacation} past={past}/>
                        </div>
                        <div className={(alignment!=='todo'?'hidden':'')+' w-full lg:flex p-2 lg:p-3'}>
                            <TodoSection dataVacation={dataVacation}/>
                        </div>
                    </div>
                    <div className="flex flex-col w-full lg:w-1/2">
                        <div className={(alignment!=='budget'?'hidden':'')+' w-full lg:flex p-2 lg:p-3'}>
                            <BudgetSection dataVacation={dataVacation}/>
                        </div>
                        <div className={(alignment!=='packing'?'hidden':'')+' w-full lg:flex p-2 lg:p-3'}>
                            <PackingSection dataVacation={dataVacation}/>
                        </div>
                        {dataVacation.itinerary[0]!==false &&
                        <div className={(alignment!=='itinerary'?'hidden':'')+' w-full lg:flex p-2 lg:p-3'}>
                            <Itinerary vacation={dataVacation} tier={''}/>
                        </div>
                        }
                    </div>
                </div>
            </vacationContext.Provider>
        </Layout>
    ) : (
        <Loading />
    )
}
const OverviewSection = ({dataVacation,past}:{dataVacation:Vacations,past:boolean}) => {
    const { t } = useTranslation();
    // const [,,userData] = useContext(UserContext);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate()

    const cancelVacation = async () => {
        if (dataVacation.id!=='examplevacation') {
            const {data, error} = await supabase.from('vacations').update({ 
                canceled:true,
            })
            .eq('id', dataVacation.id)
            .select()
            if (data) { navigate('/home') }
            else { sendError(error) }
        }
    }

    return (
        <div className="flex flex-col w-full my-2 groups rounded-lg bg-base-200 p-3">
            <h1 className='hidden lg:flex justify-center text-3xl font-extrabold text-center'>{t('overview')}</h1>
            {/* <div className='flex justify-end md:justify-start w-full md:w-1/2s'>
                <CurrencyConversion origin={userData?.currency?userData.currency:'US'} destination={dataVacation.country} />
            </div> */}
            { (dataVacation.city) && <Weather city={dataVacation.city}/> }
            { dataVacation.packing_list?.length>0 && <ProgressBar type={'🧳'} items={dataVacation.packing_list.concat(dataVacation.packing_list2).concat(dataVacation.packing_list3).concat(dataVacation.packing_list4)} /> }
            { dataVacation.todos?.length>0 && <ProgressBar type={'✅'} items={dataVacation.todos} /> }
            {(dataVacation.saved_links?.length>0) &&
                <div className='flex gap-2 flex-wrap w-full h-max p-1 my-5'>
                    {dataVacation.saved_links && dataVacation.saved_links.map((saved_link:any) => {
                        return (saved_link.description)&&<Link key={saved_link.id} target='_blank' to={saved_link.link} className='btn btn-primary'>{saved_link.description}</Link>
                    })}
                </div>
            }
            { dataVacation.notes && <div className='bg-base-300 p-3 my-5 rounded-lg'><p style={{whiteSpace: "pre-line"}}>{dataVacation.notes} </p></div> }
            <div className='mt-5'>
                {!past && 
                <button className='btn btn-error' onClick={()=>setOpen(true)}>{t("vacationCancel")}</button>
                }
                <Modal isModalOpen={open} setIsModalOpen={()=>setOpen(false)} title={t("vacationCancel")}>
                    <div className='flex flex-col items-center text-center w-full'>
                        <div className='mx-auto my-4 w-64s'>
                            <p className=''>{t('vacationCancelMsg')}</p>
                            <p className='mt-2 text-sm'>{t('vacationCancelInfo')}</p>
                        </div>
                        <div className='flex justify-around gap-4 w-full'>
                            <button className='btn btn-error w-1/3' onClick={cancelVacation}>{t("yes").toUpperCase()}</button>
                            <button className='btn btn-s w-1/3' onClick={()=>setOpen(false)}>{t("no").toUpperCase()}</button>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    )
}
const PackingSection = ({dataVacation}:{dataVacation:Vacations}) => {
    const { t } = useTranslation();
    const [ alignment, setAlignment ] = useState('bag1')

    const handleAlignment = (newAlignment: string) => {
        if (newAlignment) setAlignment(newAlignment);
    };
    return (
        <div className="flex flex-col w-full my-2 groups rounded-lg bg-base-200 p-3">
            <h1 className='hidden lg:flex justify-center text-3xl font-extrabold text-center'>{t('packingList')}</h1>
            <div role="tablist" className="tabs tabs-boxed">
                <button role="tab" className={"tab flex gap-2 hover:bg-neutral-content"+(alignment==='bag1'?' tab-active':'')} onClick={()=>handleAlignment('bag1')} ><span className=''>{t('bag1')}</span></button>
                <button role="tab" className={"tab flex gap-2 hover:bg-neutral-content"+(alignment==='bag2'?' tab-active':'')} onClick={()=>handleAlignment('bag2')} ><span className=''>{(dataVacation.packing_list2===null||dataVacation.packing_list2.length===0)?t('newBag'):t('bag2')}</span></button>
                <button role="tab" className={"tab flex gap-2 hover:bg-neutral-content"+(alignment==='bag3'?' tab-active':'')+((dataVacation.packing_list2===null||dataVacation.packing_list2.length===0)?' hidden':'')} onClick={()=>handleAlignment('bag3')} ><span className=''>{(dataVacation.packing_list3===null||dataVacation.packing_list3.length===0)?t('newBag'):t('bag3')}</span></button>
                <button role="tab" className={"tab flex gap-2 hover:bg-neutral-content"+(alignment==='bag4'?' tab-active':'')+((dataVacation.packing_list3===null||dataVacation.packing_list3.length===0)?' hidden':'')} onClick={()=>handleAlignment('bag4')} ><span className=''>{(dataVacation.packing_list4===null||dataVacation.packing_list4.length===0)?t('newBag'):t('bag4')}</span></button>
            </div>
            <DndProvider backend={(isBrowser)?HTML5Backend:TouchBackend} options={opt}>
                { alignment==="bag1" && <PackingContainer list={dataVacation.packing_list} vacationId={dataVacation.id} number={1} /> }
                { alignment==="bag2" && <PackingContainer list={dataVacation.packing_list2} vacationId={dataVacation.id} number={2} /> }
                { alignment==="bag3" && <PackingContainer list={dataVacation.packing_list3} vacationId={dataVacation.id} number={3} /> }
                { alignment==="bag4" && <PackingContainer list={dataVacation.packing_list4} vacationId={dataVacation.id} number={4} /> }
            </DndProvider>
        </div>
    )
}
const TodoSection = ({dataVacation}:{dataVacation:Vacations}) => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col w-full my-2 groups rounded-lg bg-base-200 p-3">
            <h1 className='hidden lg:flex justify-center text-3xl font-extrabold text-center'>{t('todos')}</h1>
            <DndProvider backend={(isBrowser)?HTML5Backend:TouchBackend} options={opt}>
                <TodoContainer todos={dataVacation.todos} vacationId={dataVacation.id} />
            </DndProvider>
        </div>
    )
}
const BudgetSection = ({dataVacation}:{dataVacation:Vacations}) => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-col w-full my-2 groups rounded-lg bg-base-200 p-3">
            <h1 className='hidden lg:flex justify-center text-3xl font-extrabold text-center'>{t('budget')}</h1>
            <Budget vacation={dataVacation} />
        </div>
    )
}
const Itinerary = ({vacation,tier}:{vacation:Vacations,tier:string}) => {
    const [dataVacation, setDataVacation] = useContext(vacationContext);
    const { t } = useTranslation();
    const [editingItemId, setEditingItemId] = useState<string>('');
    const [newItineraryText, setNewItineraryText] = useState<string>('');
    const [dataItinerary, setDataItinerary] = useState<Array<DateObject>>([{'':''}])

    useEffect(()=>{;
        const getData = async () => {
            // const {data: vacationTmp, error} = await supabase.from('vacations').select('id, itinerary, date_start, date_end').eq('id', vacationId).single();
            if (vacation.id!=='examplevacation') { 
                if (!vacation.itinerary) {
                    const newItinerary = updateItinerary(vacation.itinerary,vacation.date_start,vacation.date_end,t('emptyDay'));
                    vacation.itinerary=newItinerary

                    const {data, error} = await supabase.from('vacations').update({ 
                        itinerary:newItinerary,
                    })
                    .eq('id', vacation.id)
                    .select()
                    if (!data) {sendError(error)}
                }
                else {
                    let dates = getDatesInBetween(vacation.date_start,vacation.date_end);
                    let currentItinerary = vacation.itinerary.map((dates:DateObject) => { return Object.keys(dates)[0] })

                    if (currentItinerary[0]!==dates[0]||currentItinerary[currentItinerary.length-1]!==dates[dates.length-1]) {
                        const newItinerary = updateItinerary(vacation.itinerary,vacation.date_start,vacation.date_end,t('emptyDay'))                        
                        vacation.itinerary=newItinerary

                        const {data, error} = await supabase.from('vacations').update({ 
                            itinerary:newItinerary,
                        })
                        .eq('id', vacation.id)
                        .select()
                        if (!data) {sendError(error)}
                    }                    
                }
                setDataItinerary(vacation.itinerary)
            }
            else { setDataItinerary(vacation.itinerary) };
        }
        getData()
        
    },[vacation,t])

    const handleSave = (date:string) => {
        setEditingItemId('');
        
        setDataItinerary((prevItinerary:DateObject[]) =>
            prevItinerary.map(itinerary =>
                Object.keys(itinerary)[0] === date ? { ...itinerary, [date]: newItineraryText } : itinerary
            )
        );
        const updatedList = dataItinerary.map((itinerary:DateObject) =>
            Object.keys(itinerary)[0] === date ? { ...itinerary, [date]: newItineraryText } : itinerary
        )
        if (vacation.id!=='examplevacation') {
            saveInDB(updatedList)
        }
        setNewItineraryText('')
    }
    const handleEdit = (date:string,dateVal:string) => {
        setEditingItemId(date);
        setNewItineraryText(dateVal)
    };
    const handleRevert = () => {
        setEditingItemId('');
        setNewItineraryText('')
    }
    const saveInDB = async (newItinerary:DateObject[]) => {
        const {data, error} = await supabase.from('vacations').update({ 
            itinerary:newItinerary,
        })
        .eq('id', vacation.id)
        .select()
        .single()
        if (dataVacation) {}
        if (data) { 
            setDataVacation(data);
            // sendNotify(t('saved'))
        }
        else { sendError(error) }
    }

    return (
        <div className="flex flex-col w-full my-2 groups rounded-lg bg-base-200 p-3">
            <h1 className='hidden lg:flex justify-center text-3xl font-extrabold text-center'>{t('itinerary')}</h1>
            {dataItinerary.map((date:DateObject) => {
                const dateVal=Object.keys(date)[0];
                return <div key={dateVal} className='my-3 bg-base-300 p-3 rounded-lg'>
                    <div className='flex justify-between'>
                        <h1 className='text-2xl md:text-3xl'>{dateVal}</h1>
                        {editingItemId===dateVal ? <div className='flex gap-3'><button onClick={()=>handleSave(dateVal)}><Save size="20" /></button><button onClick={handleRevert}><Undo size="20" /></button></div> : <button onClick={()=>handleEdit(dateVal,date[dateVal])}><Pencil size="20" /></button> }
                    </div>
                    {editingItemId===dateVal ? 
                    <textarea autoFocus onFocus={e => e.currentTarget.select()} className="textarea textarea-bordered w-full textarea-sm" id="outlined-basic" rows={4} defaultValue={date[dateVal]} onChange={(e)=>setNewItineraryText(e.target.value)}/>
                    :
                    <p style={{whiteSpace: "pre-line"}} className=''>{date[dateVal]}</p> }
                </div>
            })}
        </div>
    )
}