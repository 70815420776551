import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Country } from "country-state-city";
import i18nIsoCountries from 'i18n-iso-countries';
import countryToCurrency from 'country-to-currency';
import { UserContext } from "../../../App";
import supabase from "../../../utils/supabase";
import { getTier, sendError } from "../../../utils/functions";
import { Layout, Loading } from "../../../components/GeneralComponents";
import Select from 'react-select'
import TemplatePacking from "./TemplatePacking";
import TemplateTodo from "./TemplateTodo";
import { Helmet } from "react-helmet-async";

const localCurrency: { label: string; value: string }[]=Country.getAllCountries().map(country=>( {label:countryToCurrency[country.isoCode as keyof typeof countryToCurrency]+' ('+i18nIsoCountries.getName(country.isoCode, 'en')+')',value:country.isoCode} ))

export default function Settings() {
    const { t } = useTranslation();
    const [,,userData] = useContext(UserContext);
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [templatesTodos, setTemplatesTodos] = useState<Templates>()
    const [templatesPacking, setTemplatesPacking] = useState<Templates>()
    const [hasChanged, setHasChanged] = useState(false)
    const [countriesList, setCountriesList] = useState<any>()
    
    
    const [settingsDetails, setSettingsDetails] = useState<any>()
    useEffect(()=>{
        if (userData) {
            setCountriesList(Country.getAllCountries().map(country=>( {label:i18nIsoCountries.getName(country.isoCode, userData.lang),value:country.isoCode} )))
        }
        delete userData?.moreAllowed;
        setSettingsDetails(userData)
    },[userData])
    useEffect(()=>{
        if (userData&&!templatesTodos&&!templatesPacking) { getTemplates() }
    },[templatesTodos,templatesPacking,userData])
    useEffect(()=>{
        if (!hasChanged) return;
        const handleOnBeforeUnload = (event:BeforeUnloadEvent) => {
            event.preventDefault();
            return (event.returnValue='');
        }
        window.addEventListener('beforeunload',handleOnBeforeUnload,{capture:true});
        return () => {
            window.removeEventListener('beforeunload',handleOnBeforeUnload,{capture:true});
        }
    },[hasChanged])

    const getTemplates = async () => {
        const { data:todos,error:errorTodo } = await supabase.from('templates').select('*').eq("del", false).eq("type", "todo")
        const { data:packing,error:errorPacking } = await supabase.from('templates').select('*').eq("del", false).eq("type", "packing")
        if (todos) { setTemplatesTodos(todos) }
        else { sendError(errorTodo) }
        if (packing) { setTemplatesPacking(packing)  }
        else { sendError(errorPacking) }
    }
    const handleSave = async (e:any) => {
        e.preventDefault();
        // console.log(settingsDetails);
        // return false;
        setHasChanged(false);
        setLoading(true)
        const { data, error } = await supabase.from('users').update({
            ...settingsDetails,
            // full_name:inputs.full_name,
            // lang:lang,
            // passport:passport,
            // currency:currency,
            // countries_visited:countriesVisited
        })
        .eq('id', userData.id)
        .select()
        console.log();
        
        if (data) { navigate(0); }
        else { sendError(error) }
        setLoading(false)
    }
    const handlePassportChange = (e:any) => {
        setSettingsDetails({
            ...settingsDetails,
            passport: e.value
        });
    }
    const handleCurrencyChange = (e:any) => {
        setSettingsDetails({
            ...settingsDetails,
            currency: e.value
        });
    }
    const handleLangChange = (e:any) => {
        setSettingsDetails({
            ...settingsDetails,
            lang: e.value
        });
    }
    const handleSettingsChanges = (e:any) => {
        setSettingsDetails({
            ...settingsDetails,
            [e.target.name]: e.target.value
        });
    }
    const addTemplateTodo = async () => {
        const { data, error } = await supabase.from('templates').insert({
            name:t('newTemplateName'),
            type:'todo',
            user_id:userData.id,
            details:[]
        })
        .select()
        if (data) { getTemplates() }
        else { sendError(error) }
    }
    const addTemplatePacking = async () => {
        const { data, error } = await supabase.from('templates').insert({
            name:t('newTemplateName'),
            type:'packing',
            user_id:userData.id,
            details:[]
        })
        .select()
        if (data) { getTemplates() }
        else { sendError(error) }
    }
    // const handlePortal = async () => {
    //     const {data,error} = await supabase.functions.invoke("stripe-customer-portal");
    //     if (data) {
    //         const dataDe=JSON.parse(data);
    //         window.location.href=dataDe.url
    //     }
    //     else { sendError(error) }
    // }

    return userData&&countriesList ? (
        <Layout>
            <Helmet>
                <title>{t('settings')+' | Vacation Vision'}</title>
            </Helmet>
            <h1 className="text-3xl md:text-4xl font-extrabold">{t('userSettings')}</h1>
            <div className="flex flex-col w-full my-2 groups rounded-lg bg-base-200 p-3">
                <form className='card-item flex flex-col gap-4' onSubmit={handleSave} >
                    {/* <label className="form-control w-full">
                        <div className="pl-1"><span className="label-text-alt">{t('email')}</span></div>
                        <input disabled className="input input-bordered input-sm" type="text" name="email" value={settingsDetails.email} onChange={handleSettingsChanges} />
                    </label> */}
                    <label className="form-control w-full">
                        <div className="pl-1"><span className="label-text-alt">{t('yourName')}</span></div>
                        <input className="input input-bordered input-sm" type="text" name="full_name" value={settingsDetails.full_name} onChange={handleSettingsChanges} />
                    </label>
                    <label className="form-control w-full">
                        <div className="pl-1">
                            <span className="label-text-alt">{t("lang")}</span>
                        </div>
                        <Select options={[{value:"de",label:t("de")},{value:"en",label:t("en")}]} defaultValue={{value:settingsDetails?.lang,label:t(settingsDetails?.lang)}} onChange={handleLangChange} placeholder='- -' theme={(theme:any)=>({...theme,borderRadius: "var(--rounded-btn, 0.5rem)"})} />
                    </label>
                    <label className="form-control w-full">
                        <div className="label spl-1">
                            <span className="label-text-alt">{t("passport")}</span>
                        </div>
                        <Select options={countriesList} defaultValue={countriesList.find((country:labelAndValue)=>country.value===settingsDetails?.passport)} onChange={handlePassportChange} placeholder='- -' theme={(theme:any)=>({...theme,borderRadius: "var(--rounded-btn, 0.5rem)"})} />
                        <div className="label">
                            <span className="label-text-alt">{t("passportExplain")}</span>
                        </div>
                    </label>
                    <label className="form-control w-full">
                        <div className="label spl-1">
                            <span className="label-text-alt">{t("currency")}</span>
                        </div>
                        <Select options={localCurrency} defaultValue={localCurrency.find((currency:labelAndValue)=>currency.value===settingsDetails?.currency)} onChange={handleCurrencyChange} placeholder='- -' theme={(theme:any)=>({...theme,borderRadius: "var(--rounded-btn, 0.5rem)"})} />
                    </label>
                    <div className="flex justify-between m-2s smt-5">
                        <button className="btn btn-primary" type="submit">{(loading)?t('loading'):t('save')}</button>
                        <Link to="/feedback" className="btn btn-outline btn-primary">{t("support")}</Link>
                    </div>
                </form>
            </div>
            {getTier(userData?.tier) ?
                <div className="flex flex-col lg:flex-row gap-5 lg:gap-0 w-full my-2 groups rounded-lg bg-base-200 p-3 mt-5">
                    <div className="m-3 w-full">
                        <h1 className="mb-2">✓ <b>{t("template")+' '+t("todos")}</b></h1>
                        <div className="flex gap-3 flex-wrap">
                            {(templatesTodos) && templatesTodos?.map((todo:any) => {
                                return <TemplateTodo key={todo.id} todo={todo} />
                            })}
                            <button className='btn btn-sm btn-primary' onClick={addTemplateTodo}>+</button>
                        </div>  
                    </div>
                    <div className="m-3 w-full">
                        <h1 className="mb-2">🧳 <b>{t("template")+' '+t("packingList")}</b></h1>
                        <div className="flex gap-3 flex-wrap">
                            {(templatesPacking) && templatesPacking?.map((item:any) => {
                                return <TemplatePacking key={item.id} item={item} />
                            })}
                            <button className='btn btn-sm btn-primary' onClick={addTemplatePacking}>+</button>
                        </div>  
                    </div>
                </div>
            :
                <></>
            }
                    {/* {(getTier(userData?.tier)&&getTier(userData?.tier)<10) ? <button className="btn-item btn-primary w-40 mt-5" onClick={handlePortal}>Manage Plan</button> : <></> } */}
        </Layout>
    ) : (
        <Loading />
    )
}