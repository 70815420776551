import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import supabase from '../../utils/supabase';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../App';
import { today} from '../../utils/functions';
import VacationItem from '../../components/VacationItem';
import { Plus } from 'lucide-react';
import { Layout, Loading } from '../../components/GeneralComponents';
import i18nIsoCountries from 'i18n-iso-countries';
import { Helmet } from 'react-helmet-async';

/* 
 * First Version: Aug 25, 2023
 * Version Nameing:
 * "1.xx.x" -> 1st year of release
 * "x.08.x" -> August
 * "x.xx.25" -> 25
 * HACK: change inputs for login/register
 * https://stackoverflow.com/questions/61042455/i-can-t-install-pwa-by-clicking-on-the-button
*/
// FIXME: CHANGE UPDATES! FIXME:
// const VERSION = "2.08.27"

function Dashboard() {
    const { t } = useTranslation();
    const [,,userData] = useContext(UserContext);
    const navigate = useNavigate()
    // const [currentVacation, setCurrentVacation] = useState<Database>();
    const [pastYears, setPastYears] = useState([]);
    const [searchVacation, setSearchVacation] = useState("");
    const [futureVacations, setfutureVacations] = useState<Database>();
    const [canceledVacations, setcanceledVacations] = useState<Database>();
    const [pastVacation, setPastVacation] = useState<Database>();
    const [loaded, setLoaded] = useState(false);


    useEffect(()=>{
        const getPastVacationData = async () => {
            const { data } = await supabase.from('vacations').select('id,country,description_name,image,date_end').lte('date_end', today).eq('canceled', false).order('date_end', { ascending: false });
            if (data?.length) {
                setPastVacation(data)
                
                const arr=data.map(vacation=>vacation.date_end.substring(0,4))
                const arrReduce=arr.reduce((unique,item)=>unique.includes(item)?unique:[...unique,item],[])
                setPastYears(arrReduce)
            }
        }
        const getFutureVacationData = async () => {
            const { data } = await supabase.from('vacations').select('id,country,description_name,date_start,image,city').gte('date_end', today).eq('canceled', false).order('date_start', { ascending: true });
            if (data?.length) {
                setfutureVacations(data)
            }
            else navigate('/new')
            setLoaded(true)
        }
        const getCancelledVacationData = async () => {
            const { data } = await supabase.from('vacations').select('id,country,description_name,date_start,image,city').eq('canceled', true).order('date_start', { ascending: true });
            if (data?.length) {
                setcanceledVacations(data)
            }
        }
        getFutureVacationData()
        getPastVacationData()
        getCancelledVacationData()
    },[navigate])

    return loaded ? (
            <Layout>
                <Helmet>
                    <title>Vacation Vision | Your Vacations</title>
                </Helmet>
                <h1 className="text-3xl md:text-4xl font-extrabold">{t("welcome")} {(userData?.full_name)??userData?.full_name}</h1>
                <div className='flex items-center gap-2'>
                    <input className="input input-bordered input-sm w-full" type="text" id="search" placeholder={t('searchVacations')} onChange={(e:any)=>setSearchVacation(e.target.value)} value={searchVacation}></input>
                    <Link className='btn btn-sm btn-primary btn-circle' to={'/new'}><Plus /></Link>
                </div>
                {(!userData?.full_name) && //FIXME: Modal
                    <div className='bg-red-400 border-red-600 border rounded-xl p-2 py-3 w-full my-4'>
                        <p>{t("addInfo")}</p>
                        <Link to="/settings" className='underline'>{t("goSettings")}</Link>
                    </div>
                }
                {futureVacations && 
                    <>
                        <h1 className="text-center mt-8 font-extrabold">{t("futureTrips")}</h1>
                        <div className="flex flex-wrap">
                            {futureVacations.map((vacation:Vacations)=>{
                                if (searchVacation==="" || vacation.description_name.toLowerCase().includes(searchVacation.toLowerCase()) || i18nIsoCountries.getName(vacation.country, userData.lang)?.toLowerCase().includes(searchVacation.toLowerCase()) ) return <VacationItem key={vacation.id} vacation={vacation}/>;
                                else return <></>
                            })}
                        </div>
                    </>
                }
                {pastVacation &&            
                    pastYears.map((year)=>{
                        return <>
                            <h1 className="text-center mt-8 font-extrabold">{year}</h1>
                            <div className="flex flex-wrap">
                            {pastVacation.map((vacation:Vacations)=>{
                                if (vacation.date_end.substring(0,4)!==year) return <></>;
                                if (searchVacation==="" || vacation.description_name.toLowerCase().includes(searchVacation.toLowerCase()) || i18nIsoCountries.getName(vacation.country, userData.lang)?.toLowerCase().includes(searchVacation.toLowerCase()) ) return <VacationItem key={vacation.id} vacation={vacation} past />;
                                else return <></>;
                            })}
                            </div>
                        </>
                    })
                }
                {canceledVacations && 
                    <>
                        <h1 className="text-center mt-8 font-extrabold">{t("canceled")}</h1>
                        <div className="flex flex-wrap">
                            {canceledVacations.map((vacation:Vacations)=>{
                                if (searchVacation==="" || vacation.description_name.toLowerCase().includes(searchVacation.toLowerCase()) || i18nIsoCountries.getName(vacation.country, userData.lang)?.toLowerCase().includes(searchVacation.toLowerCase()) ) return <VacationItem key={vacation.id} vacation={vacation} past />;
                                else return <></>
                            })}
                        </div>
                    </>
                }
            </Layout>
    ) : (
        <Loading />
    )
}

export default Dashboard;

/*
<head>
    <script type="application/ld+json">
    {
        "@context": "https://schema.org",
        "@type": "WebSite",
        "name": "Vacation Vision",
        "url": "https://vacation-vision.com",
        "description": "Your all-in-one vacation planning system. Create packing lists, to-do lists, manage your trip budget, get weather and visa information.",
        "sameAs": [
            "https://www.facebook.com/vacationvision",
            "https://www.twitter.com/vacationvision"
        ]
    }
    </script>
</head>
*/