import { useContext } from 'react';
import { UserContext } from '../../../App';
import Announce from './Announce';
// import FAQ from './FAQ';
import FeaturesAccordion from './FeaturesAccordion';
import Footer from './Footer';
import Hero from './Hero';
import { Link } from 'react-router-dom';
import { ArrowBigRightDash } from 'lucide-react';
import { Helmet } from 'react-helmet-async';
// import LoginStart from './LoginStart';
// import Pricing from './Pricing';

export default function LandingPage({loggedin=false}) {
    const [,,userData] = useContext(UserContext);
    return (
        <>
            <Helmet>
                <title>Vacation Vision | Your All In One Vacation Planner For Your Dream Vacation</title>
            </Helmet>
            {userData && <div className='flex justify-center mt-4'><Link to="/home" className='btn btn-primary'>Dashboard<ArrowBigRightDash /></Link></div> }
            <Hero />
            <FeaturesAccordion />
            {/* <FAQ /> */}
            <Announce />
            {/* <Pricing /> */}
            {/* <LoginStart /> */}
            <Footer />
        </>
    )
}
