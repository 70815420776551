import React from 'react'

export default function VacationFormTemplates({setTodos,templates,name}:{setTodos:any,templates?:Templates,name:string}) {
    const handleTemplateTodo = (e:any) => {
        if (e.target.value==='__blank__') { setTodos([]); }
        else {
            templates.map((template:Templates)=>{ 
                if (template.name===e.target.value) { 
                    setTodos(template.details);
                    return true;
                }
                else return false;
            })
        }
    }

    const templateOptions:Array<LabelAndValue> = [{value:'__blank__',label:'blank'}]
    templates?.map((template:Templates) => {
        templateOptions.push({value:template.name,label:template.name});
        return true;
    })

    return (
        <div className="flex flex-col items-start w-full">
            <div className="flex items-center gap-3 w-full mb-3">
                {templateOptions.length>1&&
                <label className="form-control w-full">
                    <div className="pl-1">
                        <span className="label-text">{name}</span>
                    </div>
                    <select className="select select-bordered select-sm" onChange={handleTemplateTodo}>
                        <option disabled selected>- -</option>
                        {templateOptions.map((option:any)=>( <option  value={option.value}>{option.label}</option> ))}
                    </select>
                </label>
                // selected={option.value===vacationChange?.country}
                    // <FormControl variant="outlined" fullWidth>
                    //     <InputLabel id="template-select-label">{name}</InputLabel>
                    //     <Select labelId="template-select-label" id="template-select" defaultValue={''} label={name} onChange={(e)=>{handleTemplateTodo(e.target.value)}} >
                    //         {templateOptions.map((option:any)=>{ return <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem> })}
                    //     </Select>
                    // </FormControl>
                }
            </div>
        </div>
    )
}
