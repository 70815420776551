import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../../App";
import supabase from "../../../utils/supabase";
import { sendTelegramFeedback } from "../../../utils/telegram";
import { sendError } from "../../../utils/functions";
import { Layout, Loading } from "../../../components/GeneralComponents";
import Select from 'react-select'

export default function Feedback() {
    const { t } = useTranslation();
    const [,,userData] = useContext(UserContext);
    const [sent, setSent] = useState<boolean>(false)
    const [hasChanged, setHasChanged] = useState(false)
    const [formDetails, setFormDetails] = useState({msg:'',type:'Bug',email:userData.email})

    useEffect(()=>{
        if (!hasChanged) return;
        const handleOnBeforeUnload = (event:BeforeUnloadEvent) => {
            event.preventDefault();
            return (event.returnValue='');
        }
        window.addEventListener('beforeunload',handleOnBeforeUnload,{capture:true});
        return () => {
            window.removeEventListener('beforeunload',handleOnBeforeUnload,{capture:true});
        }
    },[hasChanged])
    const handleFeedbackChange = (e:any) => {
        setFormDetails({
            ...formDetails,
            [e.target.name]: e.target.value
        });
    }
    const handleTypeChange = (e:any) => {
        setFormDetails({
            ...formDetails,
            type: e.value
        });
    }
    const saveFeedback = async (e:any) => {
        e.preventDefault();
        console.log(formDetails);
        
        setHasChanged(false);
        const { data, error } = await supabase.from('feedback').insert({
            ...formDetails,
            user_id: userData.id,
            user_name:userData.full_name,
        })
        .select()
        // console.log(data, error)
        if (data) {
            setSent(true)
            sendTelegramFeedback(userData.full_name,formDetails.msg,formDetails.type)
        }
        else { sendError(error) }
    }
    
    const feedbackTypes = [
        {value:'Bug',label:t("feedbackTypeBug")},
        {value:'Feature',label:t("feedbackTypeFeature")},
        {value:'Question',label:t("feedbackTypeQuestion")},
        {value:'Other',label:t("feedbackTypeOther")}
    ]

    return userData ? (
        <Layout>
            {!sent ?
                <div className="w-full">
                    <h1 className="text-3xl md:text-4xl font-extrabold">{t("support")}</h1>
                    <form className="card-item" onSubmit={saveFeedback} >
                        <div className="flex flex-col items-start m-2">
                            <label className="form-control w-full">
                                <div className="pl-1"><span className="label-text-alt">{t('yourName')}</span></div>
                                <input disabled className="input input-bordered input-sm" type="text" name="user_name" value={userData.full_name} onChange={handleFeedbackChange} />
                            </label>
                        </div>
                        <div className="flex flex-col items-start m-2">
                            <label className="form-control w-full">
                                <div className="pl-1">
                                    <span className="label-text-alt">{t("feedbackType")}</span>
                                </div>
                                <Select options={feedbackTypes} onChange={handleTypeChange} defaultValue={{value:'Bug',label:t("feedbackTypeBug")}} theme={(theme:any)=>({...theme,borderRadius: "var(--rounded-btn, 0.5rem)"})} />
                            </label>
                        </div>
                        <div className="flex flex-col items-start m-2">
                            <label className=" w-full form-control">
                                <div className="pl-1">
                                    <span className="label-text">{t("message")}</span>
                                </div>
                                <textarea className="textarea textarea-bordered textarea-sm lg:h-64" placeholder={t("message")} name="msg" value={formDetails?.msg??''} onChange={handleFeedbackChange} ></textarea>
                            </label>
                        </div>
                        <div className="flex justify-center">
                            <button className="btn btn-primary" type="submit" >{t("send")}</button>
                        </div>
                    </form>
                </div>
            :
                <h1 className="text-3xl md:text-4xl font-extrabold">{t('feedbackSent')}</h1>
            }
        </Layout>
    ) : (
        <Loading />
    )
}