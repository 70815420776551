import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// import { v4 } from "uuid";


// const daysOfWeekFull=["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"]
function replaceSpecialChars(str:string) {
    let specialChars = ['ł','ä','ö','ü'];  // Array of special characters
    let replaceChars = ['l','ae','oe','ue'];  // Array of corresponding replacements
    for (let i = 0; i < specialChars.length; i++) {
        let regex = new RegExp(specialChars[i], "g");
        str=str.toLowerCase().replace(regex, replaceChars[i]);
    }
    return str;
}

export default function Weather({city}:{city:string}) {
    const { t } = useTranslation();
    // const daysOfWeekShort=[t("sundayShort"),t("mondayShort"),t("tuesdayShort"),t("wednesdayShort"),t("thursdayShort"),t("fridayShort"),t("saturdayShort")]
    const daysOfWeekNormal=[t("sunday"),t("monday"),t("tuesday"),t("wednesday"),t("thursday"),t("friday"),t("saturday")]
    const days = 3;
    const [weather, setWeather] = useState<any>()
    useEffect(()=>{
        const getWeatherData = async (city:string) => {
            const url = 'https://api.weatherapi.com/v1/forecast.json?key=9ded3627fbec4d66a7c50743233108&q='+replaceSpecialChars(city)+'&days='+days;
            const res = await fetch(url);
            const json = await res.json();
            // console.log(json.forecast.forecastday);
            
            if (!json.error) { setWeather(json.forecast.forecastday) }
        }
        getWeatherData(city)
    },[city,days])

    // const nth = (date:number) => {
    //     const dString = String(date);
    //     const last = +dString.slice(-2);
    //     if (last > 3 && last < 21) return date+t("day4");
    //     switch (last % 10) {
    //         case 1:  return date+t("day1");
    //         case 2:  return date+t("day2");
    //         case 3:  return date+t("day3");
    //         default: return date+t("day4");
    //     }
    // };
    
    return weather && (
        <div className="mb-2">
            <div className="flex flex-col rounded-lg p-3 mt-2 w-full bg-base-100">
                <div className="flex justify-between items-center w-full">
                    <img className="w-12 h-12" src={weather[0].day.condition.icon} alt="" />
                    <div className="flex flex-col items-end">
                        <p className="font-extrabold">{city}</p>
                        <p>{daysOfWeekNormal[new Date(weather[0].date).getDay()]}</p>
                    </div>
                </div>
                <div className="flex justify-between items-center mb-3">
                    <div className="">
                        <div className="text-xl font-extrabold">{Math.round(weather[0].day.maxtemp_c)}°C / {Math.round(weather[0].day.maxtemp_f)}°F</div>
                        <div>{Math.round(weather[0].day.mintemp_c)}°C / {Math.round(weather[0].day.mintemp_f)}°F</div>
                    </div>
                    <div className="flex">
                        <div className="flex flex-col items-center text-xs m-1">
                            <div>6am</div>
                            <img className="w-10 h-10" src={weather[0].hour[6].condition.icon} alt="" />
                            <div>{Math.round(weather[0].hour[6].temp_c)}°C</div>
                            <div>{Math.round(weather[0].hour[6].temp_f)}°F</div>
                        </div>
                        <div className="sm:flex flex-col items-center text-xs m-1 hidden">
                            <div>9am</div>
                            <img className="w-10 h-10" src={weather[0].hour[9].condition.icon} alt="" />
                            <div>{Math.round(weather[0].hour[9].temp_c)}°C</div>
                            <div>{Math.round(weather[0].hour[9].temp_f)}°F</div>
                        </div>
                        <div className="flex flex-col items-center text-xs m-1">
                            <div>12pm</div>
                            <img className="w-10 h-10" src={weather[0].hour[12].condition.icon} alt="" />
                            <div>{Math.round(weather[0].hour[12].temp_c)}°C</div>
                            <div>{Math.round(weather[0].hour[12].temp_f)}°F</div>
                        </div>
                        <div className="sm:flex flex-col items-center text-xs m-1 hidden">
                            <div>3pm</div>
                            <img className="w-10 h-10" src={weather[0].hour[15].condition.icon} alt="" />
                            <div>{Math.round(weather[0].hour[15].temp_c)}°C</div>
                            <div>{Math.round(weather[0].hour[15].temp_f)}°F</div>
                        </div>
                        <div className="flex flex-col items-center text-xs m-1">
                            <div>6pm</div>
                            <img className="w-10 h-10" src={weather[0].hour[18].condition.icon} alt="" />
                            <div>{Math.round(weather[0].hour[18].temp_c)}°C</div>
                            <div>{Math.round(weather[0].hour[18].temp_f)}°F</div>
                        </div>
                        <div className="sm:flex flex-col items-center text-xs m-1 hidden">
                            <div>9pm</div>
                            <img className="w-10 h-10" src={weather[0].hour[21].condition.icon} alt="" />
                            <div>{Math.round(weather[0].hour[21].temp_c)}°C</div>
                            <div>{Math.round(weather[0].hour[21].temp_f)}°F</div>
                        </div>
                    </div>
                </div>
                <div className="bg-base-300 rounded-lg py-1 px-4 w-full">
                    <div className="flex justify-between items-center">
                        <div className="font-extrabold">{daysOfWeekNormal[new Date(weather[1].date).getDay()]}</div>
                        <div className="flex items-center">
                            <img className="w-12 h-12" src={weather[0].day.condition.icon} alt="" />
                            <div className="flex flex-col items-center my-2 text-xs">
                                <div>{Math.round(weather[1].day.mintemp_c)}°C / {Math.round(weather[1].day.mintemp_f)}°F</div>
                                <div>{Math.round(weather[1].day.maxtemp_c)}°C / {Math.round(weather[1].day.maxtemp_f)}°F</div>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-between items-center">
                        <div className="font-extrabold">{daysOfWeekNormal[new Date(weather[2].date).getDay()]}</div>
                        <div className="flex items-center">
                            <img className="w-12 h-12" src={weather[0].day.condition.icon} alt="" />
                            <div className="flex flex-col items-center my-2 text-xs">
                                <div>{Math.round(weather[2].day.mintemp_c)}°C / {Math.round(weather[2].day.mintemp_f)}°F</div>
                                <div>{Math.round(weather[2].day.maxtemp_c)}°C / {Math.round(weather[2].day.maxtemp_f)}°F</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex w-full justify-center my-2">
                    <Link to={'https://www.google.com/search?q='+city+'+weather'} target="_blank">☀️ <span className='underline'>{t("weatherForecast")}</span></Link>
                </div>
            </div>
        </div>
    )
}