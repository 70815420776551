import { useTranslation } from "react-i18next";
import Modal from "../../../components/Modal";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { v4 } from "uuid";
import supabase from "../../../utils/supabase";
import { sendError } from "../../../utils/functions";
import { Pencil, Save, Trash } from "lucide-react";

type InputItem = {
    id: string;
    item: string;
    amount: number,
    packed: boolean;
}

export default function TemplatePacking({item}:{item:Templates}) {
    const { t } = useTranslation();
    const navigate = useNavigate()

    const [packingList, setPackingList] = useState<InputItem[]>(item.details);
    const [open, setOpen] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [editingItemId, setEditingItemId] = useState<string>('');
    const [templateName, setTemplateName] = useState<string>(item.name);
    const [editName, setEditName] = useState(false);

    const handleAddNewInput = (save:boolean) => {
        if (save) { 
            if (packingList[packingList.length - 1].item==='') {
                setEditingItemId('');
                setPackingList(packingList.filter(input => input.item !== ''))
                return false
            }
        }
        const newId = v4()
        setPackingList((prevTodos:InputItem[]) => [...prevTodos, { id: newId, item: '', amount:1,packed: false }]);
        handleEdit(newId)
    };
    const handleEdit = (itemId:string) => {
        setEditingItemId(itemId);
    };
    const handleItemChange = (id: string, newText: string) => {
        setPackingList((prevItems:InputItem[]) =>
            prevItems.map(input =>
                input.id === id ? { ...input, item: newText } : input
            )
        );
    };
    const handleAmountChange = (id: string, newAmountString: string) => {
        const newAmount = parseInt(newAmountString);
        
        setPackingList((prevItems:InputItem[]) =>
            prevItems.map(input =>
                input.id === id ? { ...input, amount: newAmount } : input
            )
        );
    };
    const handleSave = () => {
        setEditingItemId('');
        setPackingList(packingList.filter(input => input.item !== ''))
    };
    const handleDelete = (id: string) => {
        setPackingList((prevItems:InputItem[]) => prevItems.filter(item => item.id !== id));
    };
    const saveInDB = async (newList:InputItem[]) => {
        const {data, error} = await supabase.from('templates').update({ 
            name:templateName,
            details:newList,
        })
        .eq('id', item.id)
        .select()
        // console.log({data,error});
        if (!data) {sendError(error)}
        setOpen(false);
    };
    const handleDeleteTemplate = async (id:string) => {
        const {data, error} = await supabase.from('templates').update({ 
            del:true
        })
        .eq('id', id)
        .select()
        // console.log({data,error});
        if (!data) {sendError(error)}
        setOpenDelete(false);
        setOpen(false);
        navigate(0)
    }
    const cancel = () => {
        setPackingList(item.details);
        setTemplateName(item.name)
        setOpen(false)
    }

    return (
        <div>
            <button className='btn btn-sm btn-primary' onClick={()=>setOpen(true)}>{templateName}</button>
            <Modal isModalOpen={open} setIsModalOpen={()=>setOpen(false)} title={templateName}>
                <div className='text-center'>
                    <div className='mx-auto my-4 '>
                        {editName ? (
                            <div className='flex justify-end'>
                                <input className="input input-bordered input-sm w-9/12" autoFocus onFocus={e => e.currentTarget.select()} value={templateName} onChange={(e) => setTemplateName(e.target.value)} onKeyDown={(event) => { if (event.key === 'Enter') setEditName(true)}} />
                                <button onClick={()=>setEditName(false)} className='flex justify-center items-center w-1/6'><Save size="20" /></button>
                            </div>
                        ):(
                            <button onClick={()=>setEditName(true)}>
                                <h3 className='text-lg font-black mb-3'>{templateName}</h3>
                            </button>
                        )}
                    </div>
                    <div className='overflow-auto max-h-[calc(100vh-200px)] w-full'>
                        <div className="flex justify-start">
                            <button className="btn btn-xs btn-error" type="button" onClick={()=>setOpenDelete(true)}><Trash size="12" />{t('delete')}</button>
                        </div>
                        {packingList.map((item:InputItem) => (
                            (editingItemId === item.id ? 
                                <div key={item.id} className='flex items-center gap-2 w-full 11/12'>
                                    <button className='p-2 text-red-500 hover:text-red-600 transition' onClick={() => handleDelete(item.id)}><Trash size="20" /></button>
                                    <input className="input input-bordered input-sm w-9/12" autoFocus onFocus={e => e.currentTarget.select()} value={item.item} onChange={(e) => handleItemChange(item.id,e.target.value)} onKeyDown={(event) => { if (event.key === 'Enter') handleAddNewInput(true)}} />
                                    <input className="input input-bordered input-sm w-3/12" type="number" min={1} value={item.amount} onChange={(e) => handleAmountChange(item.id,e.target.value)} onKeyDown={(event) => { if (event.key === 'Enter') handleAddNewInput(true)}} />
                                    <button className='ml-3 p-2' onClick={() => handleSave()}><Save size="20" /></button>
                                </div>
                            :
                                <button key={item.id} className={" relative flex justify-between items-center my-3 lg:my-2 p-2 lg:p-0 w-full self-center bg-base-200 text-lg rounded-lg"} onClick={() => handleEdit(item.id)} >
                                    <label htmlFor={item.id} className='flex items-center p-2 hover:cursor-pointer w-full text-sm'>{item.item}</label>
                                    <p className='lg:text-sm'>{item.amount}</p>
                                    <button className='ml-3 p-2' onClick={() => handleEdit(item.id)}><Pencil size="20" /></button>
                                </button>
                            )
                        ))}
                        <div className="flex justify-start">
                            <button className="btn btn-sm btn-primary" type="button" onClick={()=>handleAddNewInput(false)}>{t("addNew")}</button>
                        </div>
                        
                    </div>
                    <div className='flex justify-around mt-4'>
                        <button className='btn btn-primary w-1/3' onClick={()=>saveInDB(packingList)}>{t("save")}</button>
                        <button className='btn btn-gray w-1/3' onClick={cancel}>{t("cancel")}</button>
                    </div>
                </div>
            </Modal>
            <Modal isModalOpen={openDelete} setIsModalOpen={()=>setOpenDelete(false)} title={t("delete")+' '+templateName+'?'}>
                <div className='text-center'>
                    <div className='mx-auto my-4 '>
                        <p className='text-sm'>{t("deleteTemplateMsg")}</p>
                    </div>
                    <div className='flex justify-around'>
                        <button className='btn btn-error w-1/3' onClick={()=>handleDeleteTemplate(item.id)}>{t("yes").toUpperCase()}</button>
                        <button className='btn w-1/3' onClick={()=>setOpenDelete(false)}>{t("no").toUpperCase()}</button>
                    </div>
                </div>
            </Modal>
        </div>
    )
}
