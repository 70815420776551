import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import supabase from "../../../utils/supabase";
import VacationEditNew from "../../../components/VacationEditNew";
import LinkPath from "../../../components/LinkPath";
import { useTranslation } from "react-i18next";
import { Layout, Loading } from "../../../components/GeneralComponents";

export default function DestinationsEdit() {
    const {id:vacationId} = useParams()
    const { t } = useTranslation();
    const [dataNew, setDataNew] = useState<any>()
    const [templatesTodos, setTemplatesTodos] = useState<Templates[]>()
    const [templatesPacking, setTemplatesPacking] = useState<Templates[]>()
    const navigate = useNavigate()

    const getTemplates = async () => {
        const { data:todos } = await supabase.from('templates').select('*').eq("del", false).eq("type","todo")
        if (todos) { setTemplatesTodos(todos) }
        const { data:packing } = await supabase.from('templates').select('*').eq("del", false).eq("type","packing")
        if (packing) { setTemplatesPacking(packing) }
    }
    useEffect(()=>{
        if (dataNew&&!templatesTodos) { getTemplates() }
    },[dataNew,templatesTodos])
    
    useEffect(()=>{;
        const getData = async () => {
            const {data: vacationTmp, error} = await supabase.from('vacations').select('*').eq('id', vacationId).limit(1);
            if (!error) { setDataNew(vacationTmp[0]) }
        }
        getData();
    },[navigate,vacationId])


    return dataNew ? (
        <Layout>
            <LinkPath path={[{'name':dataNew.description_name,'link':'home/'+dataNew.id},{'name':t('edit'),'link':'home/'+dataNew.id}]}/>
            <VacationEditNew vacation={dataNew} todos={dataNew.todos} templatesTodos={templatesTodos} packing={dataNew.packing_list} templatesPacking={templatesPacking}/>
        </Layout>
    ) : (
        <Loading />
    )
}