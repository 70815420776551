import React, { useEffect, useState } from 'react'
import { fetchPhotoVacation } from '../utils/functions'
import { Country } from 'country-state-city'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export default function VacationItem({vacation, past}:{vacation:Vacations,past?:boolean}) {
    const { t } = useTranslation();
    const [photoUrl, setPhotoUrl] = useState('')
    const [photoOwner, setPhotoOwner] = useState('')
    useEffect(()=>{
        const executePhotoFetch = async () => {
            const fetchPhoto = await fetchPhotoVacation(vacation);
            // console.log(fetchPhoto);
            setPhotoUrl(fetchPhoto?.url)
            setPhotoOwner(fetchPhoto?.owner)
        }
        if (vacation.image) {
            setPhotoUrl(vacation.image[0].url)
            setPhotoOwner(vacation.image[0].owner)
        }
        else {
            executePhotoFetch()
        }
    },[vacation])
    const daysUntil = (date:string) => {
        const daysBetween = Math.round((new Date(date).getTime() - new Date().getTime()) / (1000 * 3600 * 24));
        if (daysBetween===0) { return t('today') }
        else if (daysBetween===1) { return t('tomorrow') }
        else if (daysBetween===-1) { return t('dayAgo') }
        else if (daysBetween<0) { return daysBetween*-1+" "+t('daysAgo') }
        else { return t("inDays", {days:daysBetween}) }
    }
    return (
        <Link to={'/home/'+vacation.id} className="relative flex-1 min-w-80 max-w-full h-80 lg:h-96 m-2 rounded-xl hover:scale-[1.02] duration-200 item">
            <div className="absolute top-0 left-0 min-w-full min-h-full w-full h-full object-cover brightness-50">
                {vacation.image ? 
                    <img className={"object-cover w-full h-full rounded-xl "+(past?"grayscale":"")} src={photoUrl} alt=""/>
                :
                    <div className="w-full h-full rounded-lg transition duration-200 bg-slate-700"></div>
                }
            </div>
            <div className="absolute w-full h-full flex flex-col justify-between items-start py-2 px-3">
                <h1 className="font-semibold lg:text-2xl text-white">{Country.getCountryByCode(vacation.country)?.flag+' '}{vacation.description_name}</h1>
                <div className="flex justify-between items-center text-white w-full">
                    {!past && <div>{daysUntil(vacation.date_start)}</div>}
                    <div className='text-xs'>{photoOwner}</div>
                </div>
            </div>

        </Link>
    )
}
