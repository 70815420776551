import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import config from './config';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <html lang="en" data-theme={config.colors.theme}>
        <React.StrictMode>
            <HelmetProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
            </HelmetProvider>
        </React.StrictMode>
    </html>
);
